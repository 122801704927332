/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Text,
  Spinner,
  Image,
  chakra,
  Button,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  SimpleGrid,
  Box,
  useToast,
} from '@chakra-ui/react';
import { CheckCircleIcon, StarIcon } from '@chakra-ui/icons';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useFilePicker } from 'use-file-picker';
import { RiEditCircleFill } from 'react-icons/ri';
import { auth } from '../config/firebase';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { ProfessionalUser, PortfolioFeaturedWork } from '../models/ProfessionalUser';
import LoadingScreen from './LoadingScreen';
import { updateProfessionalDetails, upsertPortfolioFeaturedWork } from '../services/ProfessionalsService';
import AddIcon from './AddIcon';
import { OutfitQuery } from '../models/OutfitQuery';
import { Outfit } from '../models/Outfit';
import { fetchOutfitsBatch, searchOutfits } from '../services/OutfitsService';
import { PaginatedResult } from '../models/PaginatedResult';
import OutfitPartsDisplay from './OutfitPartsDisplay';

type UserPortfolioDisplayProps = {
  handleUpdateCompleted: () => void;
  handleUpdateProUser: (prouser: ProfessionalUser) => void;
};

function UserPortfolioDisplay({ handleUpdateCompleted, handleUpdateProUser, ...rest }: UserPortfolioDisplayProps): React.ReactElement {
  const { currentUser } = useContext(CurrentUserContext);
  const toast = useToast();
  const [user] = useAuthState(auth);
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const [prouser, setprouser] = useState<ProfessionalUser>(currentProfessional as ProfessionalUser);
  const [selectedPortfolioFeaturedWork, setSelectedPortfolioFeaturedWork] = useState<PortfolioFeaturedWork | undefined>(undefined);
  const [featuredOutfits, setFeaturedOutfits] = useState<Outfit[]>([]);
  const OUTFIT_BOX_DIMENSION = 380;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [allOutfits, setAllOutfits] = useState<Outfit[] | undefined>();
  const [loadingOutfits, setLoadingOutfits] = useState(false);
  const [showSelectOutitsModal, setShowSelectOutfitsModal] = useState(false);
  const [selectedOutfitIds, setSelectedOutfitIds] = useState<number[]>([]);

  const handleLoadSelectOutfitsModal = async () => {
    if (!user) return;
    setLoadingOutfits(true);
    const query: OutfitQuery = {
      po: 1,
      ps: 40,
      sf: 'createddate',
      sd: 'desc',
      createdby: user.uid, // this tells the service to return items for the client
      searchterm: undefined,
    };
    setShowSelectOutfitsModal(true);
    await searchOutfits(user, query)
      .then((res) => res.json())
      .then((result: PaginatedResult) => {
        setAllOutfits(result.pageItems);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingOutfits(false));
  };

  useEffect(() => {
    if (!user) return;
    if (!prouser) return;
    if (!prouser.portfolioData?.featuredOutfitIds) return;
    if (prouser.portfolioData.featuredOutfitIds.length === 0) return;

    const getFeaturedOutfits = async () => {
      await fetchOutfitsBatch(user, prouser.portfolioData.featuredOutfitIds)
        .then((res) => res.json())
        .then((result: Outfit[]) => {
          console.log('featured outfits: ', result);
          setFeaturedOutfits(result);
          return result;
        })
        .then((featuredoutfits) => {
          setSelectedOutfitIds(featuredoutfits.map((o) => o.id));
        })
        .catch((err) => console.log(err));
    };
    getFeaturedOutfits().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 200,
    imageSizeRestrictions: {
      maxHeight: 10000, // in pixels
      maxWidth: 10000,
      minHeight: 20,
      minWidth: 20,
    },
  });

  const handleSavePortfolioDetails = async () => {
    console.log('saving portfoliodetails: ', prouser);

    await upsertPortfolioFeaturedWork(user as UserAuth, prouser.portfolioData?.featuredWork, plainFiles)
      // .then((res) => res.json())
      .then(() => setCurrentProfessional(prouser))
      .then(() => clearFilePicker())
      .then(() => console.log('done upsertPortfolioFeaturedWork'))
      .catch((err) => console.log(err));

    const clone: ProfessionalUser = structuredClone(prouser);
    clone.portfolioData.featuredOutfitIds = featuredOutfits.map((o) => o.id);
    console.log('clone: ', clone);
    await updateProfessionalDetails(user as UserAuth, clone).then((result) => {
      if (result.ok) {
        // console.log(clone);
        setCurrentProfessional(clone);
        setSelectedOutfitIds(clone.portfolioData.featuredOutfitIds);
        toast({
          title: 'Portfolio Saved',
          description: 'Data saved successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      }
    });

    handleUpdateCompleted();
    handleUpdateProUser(clone);
  };

  if (!currentProfessional || !currentUser) return <LoadingScreen />;
  if (!prouser) return <LoadingScreen />;

  return (
    <>
      <Flex flex={1} p={12} width="100%" flexDir="column">
        <Flex flex={1} mb={2}>
          <Text fontSize="18" fontWeight="bold" mb={2} mr={12}>
            Past Work
          </Text>
          <AddIcon
            onClick={() =>
              setSelectedPortfolioFeaturedWork({
                id: 0,
                dateadded: new Date(),
                description: '',
                link: '',
                imageurl: '',
              } as PortfolioFeaturedWork)
            }
          />
        </Flex>
        <Flex flex={1} maxWidth={1200} overflowX="scroll">
          {prouser.portfolioData?.featuredWork &&
            prouser.portfolioData.featuredWork?.map((item, i) => (
              <Flex key={i.toString()} flexDirection="column" mx={2} alignItems="flex-start">
                <Image src={item.imageurl} fit="contain" h={48} />
                <Icon
                  as={RiEditCircleFill}
                  color="orange.400"
                  transform="translateY(-8px) translateX(950%)"
                  onClick={() => setSelectedPortfolioFeaturedWork(item)}
                />
                <Text fontWeight="bold" w={44} mb={1}>
                  {item.description}
                </Text>
                {item.link && <Link href={item.link}>{item.link}</Link>}
                {item.isHighlighted && <StarIcon color="gold" />}
              </Flex>
            ))}
          {(!prouser.portfolioData?.featuredWork || prouser.portfolioData.featuredWork?.length === 0) && (
            <Text>Showcase past work, including features, photoshoots, and more</Text>
          )}
        </Flex>
        <Flex mt={16} flexDir="column">
          <Flex flex={1} mb={4}>
            <Text fontSize="18" fontWeight="bold" mb={2} mr={12}>
              Outfits
            </Text>
            <AddIcon onClick={handleLoadSelectOutfitsModal} />
          </Flex>
          {featuredOutfits && featuredOutfits.length > 0 && (
            <Flex flex={1} maxWidth={1200} overflowX="scroll">
              {featuredOutfits.map((item, i) => (
                <Flex key={i.toString()} flexDirection="column" mx={4} alignItems="flex-start">
                  <OutfitPartsDisplay outfit={item} height={OUTFIT_BOX_DIMENSION * 0.7} width={OUTFIT_BOX_DIMENSION * 0.7} resizeRatio={0.7} />
                </Flex>
              ))}
            </Flex>
          )}
          {(!featuredOutfits || featuredOutfits.length === 0) && <Text>Showcase curated outfits to give clients a sense of your style expertise.</Text>}
        </Flex>
        <Flex mt={8} width="90%" justifyContent="space-between">
          <Button variant="darkoutline" onClick={() => setprouser(currentProfessional)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            onClick={() => {
              handleSavePortfolioDetails();
            }}
          >
            Save Portfolio
          </Button>
        </Flex>
      </Flex>
      <Modal
        size="md"
        isCentered
        isOpen={selectedPortfolioFeaturedWork !== undefined}
        onClose={() => {
          setSelectedPortfolioFeaturedWork(undefined);
          setErrorMessage(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>{selectedPortfolioFeaturedWork && selectedPortfolioFeaturedWork.id > 0 ? 'Edit' : 'Add'} Featured Work Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" m={2}>
              <Text size="sm">Project Title *</Text>
              <Input
                value={selectedPortfolioFeaturedWork?.description}
                onChange={(e) =>
                  setSelectedPortfolioFeaturedWork({
                    ...selectedPortfolioFeaturedWork,
                    description: e.target.value,
                  } as PortfolioFeaturedWork)
                }
              />
              <Text size="sm" my={2}>
                Project URL
              </Text>
              <Input
                type="url"
                my={2}
                value={selectedPortfolioFeaturedWork?.link}
                onChange={(e) =>
                  setSelectedPortfolioFeaturedWork({
                    ...selectedPortfolioFeaturedWork,
                    link: e.target.value,
                  } as PortfolioFeaturedWork)
                }
              />
              <Image src="/icons/camera_add.png" objectFit="contain" w={12} my={1} onClick={() => openFileSelector()} />
              {loading && <Spinner />}
              {errors && errors.length > 0 && <Text>{JSON.stringify(errors)}</Text>}
              {filesContent && filesContent[0] && <Image src={filesContent[0].content} maxHeight={64} fit="contain" />}
              {!filesContent[0] && selectedPortfolioFeaturedWork?.imageurl && (
                <Image src={selectedPortfolioFeaturedWork.imageurl} maxHeight={64} fit="contain" />
              )}
              <Text size="sm" mt={2}>
                Set as Featured on Profile:
              </Text>
              <Switch
                colorScheme="orange"
                isChecked={selectedPortfolioFeaturedWork?.isHighlighted}
                onChange={() =>
                  setSelectedPortfolioFeaturedWork({
                    ...selectedPortfolioFeaturedWork,
                    isHighlighted: !selectedPortfolioFeaturedWork?.isHighlighted,
                  } as PortfolioFeaturedWork)
                }
              />
              <Text mt={2} color="red.700" alignSelf="flex-end">
                {errorMessage}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="darkoutline"
              mr={4}
              onClick={() => {
                setSelectedPortfolioFeaturedWork(undefined);
                setErrorMessage(undefined);
                clearFilePicker();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              bg="red.400"
              mr={4}
              onClick={() => {
                const clone: ProfessionalUser = structuredClone(prouser);
                const existing = clone.portfolioData.featuredWork.filter((l) => l.id === selectedPortfolioFeaturedWork?.id);
                if (existing && existing[0]) {
                  clone.portfolioData.featuredWork = clone.portfolioData.featuredWork.filter((l) => l.id !== existing[0].id);
                  setprouser(clone);
                }
                setSelectedPortfolioFeaturedWork(undefined);
                setErrorMessage(undefined);
              }}
            >
              Delete
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                if (!selectedPortfolioFeaturedWork?.description) {
                  setErrorMessage('Project Title is required');
                  return;
                }
                if (!selectedPortfolioFeaturedWork.imageurl && !selectedPortfolioFeaturedWork.link && !filesContent[0]?.content) {
                  setErrorMessage('Either a Project URL or an image must be included.');
                  return;
                }
                const clone: ProfessionalUser = structuredClone(prouser);
                const existing = clone.portfolioData.featuredWork.filter((l) => l.id === selectedPortfolioFeaturedWork?.id);
                if (existing && existing[0] && selectedPortfolioFeaturedWork) {
                  // this is an update:
                  existing[0].description = selectedPortfolioFeaturedWork.description;
                  existing[0].link = selectedPortfolioFeaturedWork.link;
                  existing[0].imageurl = selectedPortfolioFeaturedWork.imageurl; // TODO: ?? where get this
                  existing[0].isHighlighted = selectedPortfolioFeaturedWork.isHighlighted;
                } else {
                  // this is a new:
                  if (!selectedPortfolioFeaturedWork) return;
                  if (!clone.portfolioData.featuredWork) clone.portfolioData.featuredWork = [];
                  if (clone.portfolioData.featuredWork.length === 0) selectedPortfolioFeaturedWork.id = 1;
                  else selectedPortfolioFeaturedWork.id = Math.max(...clone.portfolioData.featuredWork.map((i) => i.id)) + 1;
                  selectedPortfolioFeaturedWork.imageurl = filesContent && filesContent[0] ? filesContent[0].content : '';
                  clone.portfolioData.featuredWork.push(selectedPortfolioFeaturedWork as PortfolioFeaturedWork);
                }
                // console.log(clone);
                setErrorMessage(undefined);
                setprouser(clone);
                setSelectedPortfolioFeaturedWork(undefined);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size="xl" isCentered scrollBehavior="inside" isOpen={showSelectOutitsModal} onClose={() => setShowSelectOutfitsModal(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="lg" p={4}>
          <ModalHeader>Select Featured Outfits</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loadingOutfits && <Spinner />}
            {!loadingOutfits && (!allOutfits || allOutfits.length === 0) && <Text>Create some outfits for clients to feature them</Text>}
            {!loadingOutfits && (
              <SimpleGrid columns={5} minChildWidth={OUTFIT_BOX_DIMENSION * 0.5 + 20} spacing={12} flex={1} overflowY="scroll">
                {allOutfits &&
                  allOutfits.map((item, i) => (
                    <Flex key={i.toString()} flexDirection="column">
                      <OutfitPartsDisplay outfit={item} height={OUTFIT_BOX_DIMENSION * 0.5} width={OUTFIT_BOX_DIMENSION * 0.5} resizeRatio={0.5} />
                      <Flex flex={1} width="100%" justifyContent="flex-end">
                        {!selectedOutfitIds.includes(item.id) && (
                          <AddIcon
                            onClick={() => {
                              if (!prouser.portfolioData?.featuredOutfitIds?.includes(item.id)) setSelectedOutfitIds([...selectedOutfitIds, item.id]);
                            }}
                          />
                        )}
                        {selectedOutfitIds.includes(item.id) && (
                          <CheckCircleIcon
                            onClick={() => setSelectedOutfitIds([...selectedOutfitIds].filter((oid) => oid !== item.id))}
                            color="orange.400"
                            boxSize={6}
                          />
                        )}
                      </Flex>
                    </Flex>
                  ))}
              </SimpleGrid>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="darkoutline"
              mr={4}
              onClick={() => {
                setSelectedOutfitIds([]);
                setShowSelectOutfitsModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                if (!allOutfits) return;
                console.log('selectedOutfitIds: ', selectedOutfitIds);
                const selectedOutfits: Outfit[] = [...allOutfits].filter((o) => selectedOutfitIds.includes(o.id));
                // setFeaturedOutfits(featuredOutfits?.concat(selectedOutfits));
                setFeaturedOutfits(selectedOutfits);
                // setSelectedOutfitIds([]); // ??
                setShowSelectOutfitsModal(false);
              }}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UserPortfolioDisplay;
