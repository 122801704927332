/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HStack, Flex, Text, Input, useToast, Image, Spinner, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import { CurrentProfessionalContext } from '../../contexts/CurrentProfessionalProvider';
import { FeaturedContentTopicItem } from '../../models/FeaturedContentModels';
import { fetchFeaturedContentForUser } from '../../services/featuredContentService';
import LoadingScreen from '../../components/LoadingScreen';
import AddIcon from '../../components/AddIcon';

function FeaturedContentScreen() {
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const [user] = useAuthState(auth);
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const [featuredContentTopicItems, setFeaturedContentTopicItems] = useState<FeaturedContentTopicItem[] | undefined>();

  useEffect(() => {
    if (!user) return;
    const fetch = async () => {
      await fetchFeaturedContentForUser(user, user.uid)
        .then((res) => res.json())
        .then((result: FeaturedContentTopicItem[]) => {
          setFeaturedContentTopicItems(result);
        })
        .finally(() => setShowSpinner(false));
    };
    setShowSpinner(true);
    fetch().catch(console.error);
  }, [user]);

  if (showSpinner) return <LoadingScreen />;

  return (
    <Flex flex={1} flexDir="column" px={12} py={8}>
      <Flex flexDirection="column">
        <HStack>
          <Text variant="header" color="charcoal" fontWeight="bold" mr={6}>
            Content
          </Text>
          <AddIcon onClick={() => navigate('/topicitem', { state: { id: undefined } })} />
        </HStack>
        <Text my={2}>
          Use this page to create content and submit it for potential inclusion on the App home screen. The content you create here is typically going to be a
          polyvore image plus images and details on the individual items. The polyvore should fit a theme that the Donne team provides, and be tagged with the
          specified tag that Donne gives you.{' '}
        </Text>
      </Flex>
      {(!featuredContentTopicItems || featuredContentTopicItems.length === 0) && (
        <Flex flex={1} mt={8} flexDir="column" justifyContent="center" alignItems="center" p={18} borderWidth={1} width="100%" minHeight={64}>
          <Image src="/favicon.png" borderRadius="full" height={20} />
          <Text fontWeight="bold" fontSize="xl" my={4}>
            No Content To Show
          </Text>
          <Text mb={2}>Add some content to get Featured!</Text>
          <AddIcon onClick={() => navigate('/topicitem', { state: { id: undefined } })} />
        </Flex>
      )}
      {featuredContentTopicItems && featuredContentTopicItems.length > 0 && (
        <Flex flexDir="row" flexWrap="wrap">
          {featuredContentTopicItems.map((topic) => (
            <Flex
              key={topic.id.toString()}
              flexDirection="column"
              alignItems="center"
              borderWidth={0.5}
              borderColor="grey.200"
              borderRadius="md"
              p={4}
              m={2}
              minW={44}
              onClick={() => navigate('/topicitem', { state: { id: topic.id } })}
            >
              {topic.collageimageurl && <Image src={topic.collageimageurl} h={32} w={32} />}
              {!topic.collageimageurl && <Box height={32} width={32} borderWidth={1} />}
              <Text mt={2}>{topic.hashtags?.join(' • ').substring(0, 16)}</Text>
              <Text mt={1} px={6} align="center" borderRadius="lg" bg={topic.status === 'Draft' ? 'grey.50' : 'blue.400'}>
                {topic.status}
              </Text>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
}

export default FeaturedContentScreen;
