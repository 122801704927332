/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Image, Flex, Input, VStack, Text } from '@chakra-ui/react';
import React from 'react';
import { PiPencilSimpleBold } from 'react-icons/pi';
import { useFilePicker } from 'use-file-picker';
import { CollageItem } from '../../models/FeaturedContentModels';

type CollageItemComponentProps = {
  item: CollageItem;
  isDisabled: boolean;
  updateHandler: (id: number, field: string, value: string) => void;
  addImageHandler: (id: number, imgfile: File) => void;
  removeHandler: (id: number) => void;
};

function CollageItemComponent({ item, isDisabled, updateHandler, addImageHandler, removeHandler }: CollageItemComponentProps) {
  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 200,
    imageSizeRestrictions: {
      maxHeight: 10000, // in pixels
      maxWidth: 10000,
      minHeight: 20,
      minWidth: 20,
    },
    onFilesSuccessfulySelected: (data) => {
      updateHandler(item.id, 'imageurl', data.filesContent[0].content);
      addImageHandler(item.id, data.plainFiles[0]);
      clearFilePicker();
    },
  });

  return (
    <Flex flexDir="row" flex={1} alignItems="center" borderColor="grey.300" borderWidth={1} borderRadius="md" p={2} my={2}>
      <VStack flex={2} mr={2}>
        <Text>Item Link*</Text>
        <Input
          size="xs"
          borderColor="grey.400"
          borderRadius="md"
          borderWidth={1}
          disabled={isDisabled}
          value={item.link}
          onChange={(e) => updateHandler(item.id, 'link', e.target.value)}
        />
      </VStack>
      <VStack flex={1} mr={2}>
        <Text>Brand Name*</Text>
        <Input
          size="xs"
          borderColor="grey.400"
          borderRadius="md"
          borderWidth={1}
          disabled={isDisabled}
          value={item.brandname}
          onChange={(e) => updateHandler(item.id, 'brandname', e.target.value)}
        />
      </VStack>
      <VStack mr={2}>
        <Text>Image*</Text>
        {!item.imageurl && !isDisabled && <AddIcon onClick={openFileSelector} />}
        {item.imageurl && <Image src={item.imageurl} maxHeight={16} fit="contain" />}
      </VStack>
      {!isDisabled && <DeleteIcon display="flex" alignSelf="center" justifySelf="center" m={2} onClick={() => removeHandler(item.id)} />}
    </Flex>
  );
}

export default CollageItemComponent;
