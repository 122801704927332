/* eslint-disable import/prefer-default-export */
import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { FEATURED_CONTENT_API, SCHEDULE_API } from '../config/ServiceConstants';
import loggerService from './LoggerService';
import { FeaturedContentTopicItem } from '../models/FeaturedContentModels';

export function fetchFeaturedContentForUser(userAuth: UserAuth, userid: string): Promise<any> {
  const url = `${FEATURED_CONTENT_API}/topicitems/user/${userid}`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchFeaturedContentTopicTags(userAuth: UserAuth): Promise<any> {
  const url = `${FEATURED_CONTENT_API}/tags`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchFeaturedContentTopicItem(userAuth: UserAuth, id: number): Promise<any> {
  const url = `${FEATURED_CONTENT_API}/topicitems/${id}`;
  return fetchWrapper(userAuth).get(url);
}

export function deleteFeaturedContentTopicItem(userAuth: UserAuth, id: number): Promise<any> {
  const url = `${FEATURED_CONTENT_API}/topicitem/${id}`;
  return fetchWrapper(userAuth).delete(url);
}

export async function upsertFeaturedContentTopicItem(userAuth: UserAuth, model: FeaturedContentTopicItem, files: File[]): Promise<any> {
  const data: FormData = new FormData();
  files.forEach((file) => {
    data.append('imageFiles', file);
  });
  data.append('entity', JSON.stringify(model));
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
      // 'File-Name': file.name,
    },
    body: data,
  };
  const url = `${FEATURED_CONTENT_API}/topicitem/${userAuth.uid}`;
  return fetch(url, requestOptions);
}
