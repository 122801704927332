/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Box,
  Text,
  Heading,
  HStack,
  Flex,
  Textarea,
  Button,
  Image,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Progress,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Divider,
  InputGroup,
  InputRightElement,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react';
import { EditIcon, ViewIcon } from '@chakra-ui/icons';
import { MultiSelect, Option } from 'chakra-multiselect';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  auth,
  logout,
  logInWithEmailAndPassword,
  signInWithGoogle,
  signInWithApple,
  sendPasswordReset,
  registerWithEmailAndPassword,
} from '../config/firebase';
import { Application } from '../models/Application';
import { EXPERT_SERVICE_OFFERINGS } from '../models/ExpertServiceOfferings';
import '../theme/multiselect-overrides.module.css';
import LabelValueDisplayItem from '../components/LabelValueDisplayItem';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import { User } from '../models/User';
import { createProfessionalApplication, fetchProfessionalApplicationForUser } from '../services/ProfessionalsService';

function ApplicationScreen() {
  const navigate = useNavigate();
  const toast = useToast();
  const [user, loading, error] = useAuthState(auth);
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const [alreadyHasAccount, setAlreadyHasAccount] = useState<boolean | undefined>(undefined);
  const [hasExistingApplication, setHasExistingApplication] = useState<boolean | undefined>(undefined);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pwVisibility, setPwVisibility] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [applicationStep, setApplicationStep] = useState(0);
  const [applicationData, setApplicationData] = useState<Application>({
    timestamp: new Date(),
    applicant: {},
    services: [],
    experience: {},
  });

  useEffect(() => {
    if (!user) return;
    const fetchExistingApplication = async () => {
      await fetchProfessionalApplicationForUser(user, user.uid)
        .then((res) => {
          if (res.status === 204) {
            console.log('No application returned (204)');
            return null;
          }
          return res.json();
        })
        .then((result: Application | undefined | null) => {
          // console.log('result: ', result);
          if (result && result.id) {
            console.log('has existing application');
            setHasExistingApplication(true);
          } else {
            console.log('no existing application');
            setHasExistingApplication(false);
            const clone = currentUser as User;
            const prefilled: Application = {
              userid: currentUser.userid,
              timestamp: new Date(),
              applicant: {
                firstname: clone.firstname,
                lastname: clone.lastname,
                email: clone.email,
                phone: clone.phone,
                city: clone.address?.city,
                state: clone.address?.stateCode,
                zipcode: clone.address?.zipCode?.toString(),
              },
              experience: {},
            };
            setApplicationData(prefilled);
            setApplicationStep(2);
          }
        });
    };

    if (currentUser && !currentProfessional) {
      setAlreadyHasAccount(true);
      if (hasExistingApplication === undefined) fetchExistingApplication().catch(console.error);
    }
  }, [currentUser, currentProfessional, user, hasExistingApplication]);

  // useEffect(() => {
  //   if (currentUser && !currentProfessional) {
  //     const clone = currentUser as User;
  //     setAlreadyHasAccount(true);

  //     // check to make sure that the user does not already have an existing application

  //     const prefilled: Application = {
  //       userid: currentUser.userid,
  //       timestamp: new Date(),
  //       applicant: {
  //         firstname: clone.firstname,
  //         lastname: clone.lastname,
  //         email: clone.email,
  //         phone: clone.phone,
  //         city: clone.address?.city,
  //         state: clone.address?.stateCode,
  //         zipcode: clone.address?.zipCode,
  //       },
  //       experience: {},
  //     };
  //     setApplicationData(prefilled);
  //     setApplicationStep(2);
  //   }
  // }, [currentUser, currentProfessional]);

  const onHandleSignup = async () => {
    try {
      if (email !== '' && password !== '') {
        await registerWithEmailAndPassword('name', email, password).then((res) => {
          // const userCred: UserCredential = res;
          console.log('account created');
          console.log(res); // void so undefined
        });
      } else {
        setInvalid(true);
        // setSignupError(INSUFFICIENT_CREDENTIALS_MSG);
      }
    } catch (err: any) {
      setInvalid(true);
      // setSignupError(AUTH_ERR_CODES[error.code]);
    }
  };

  const updateApplicationDataField = (field: string, value: any) => {
    const clone = structuredClone(applicationData);
    const pList = field.split('.');
    const key = pList.pop();
    const pointer = pList.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, clone);
    if (key) pointer[key] = value;

    setApplicationData(clone);
  };

  const handleSubmitApplication = async () => {
    if (!user) return;
    // applicationData.userid = user.uid;
    await createProfessionalApplication(user, user?.uid, applicationData)
      // .then((res) => res.json())
      .then((res) => {
        console.log('res:', res);
        if (!res.ok) return null;
        return res.json();
      })
      .then((result) => {
        if (result) {
          console.log(result);
          setApplicationStep(0);
          toast({
            title: 'Application Submitted',
            description: 'Thank you! We will be in touch-',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Something went wrong',
            description: 'Sorry about this, please try again, or email us at info@donneapp.com',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((er) => console.log(er));
  };

  const renderLandingView = () => (
    <Flex wrap="wrap" mt={[2, 2, 2, 10, 16]}>
      <Flex flexDir="column" flex={1} px={[2, 2, 4, 6, 12]} mx={[2, 2, 4, 6, 12]}>
        <Text color="orange.400" fontSize={['xl', 'xl', '2xl', '3xl', '4xl']} fontWeight={900} fontFamily="interbold">
          Apply to be a styling professional on Donne.
        </Text>
        <Text color="charcoal" my={[2, 4, 6, 8]} fontSize={['lg', 'xl', '2xl']} fontWeight={700}>
          Streamline and scale your services
        </Text>
        <Accordion color="charcoal">
          <AccordionItem borderTopWidth={0} py={4}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontWeight="bold" color="charcoal">
                  <Text color="charcoal">Virtually access your client&apos;s wardrobes</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Using digital wardrobes, in-app stylists can use professional expertise to create outfit recommendations or wardrobe overhauls for clients.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem py={4}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                  Manage booking, payments & scheduling
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>One tool to schedule appointments, track your time, generate and collect invoices.</AccordionPanel>
          </AccordionItem>
          <AccordionItem py={4}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                  Expand business and clientele
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Get connected with potential clients, both local and virtual. Get matched to the people who are your best fit.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem py={4}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                  Track analytics and get style insights
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>All the data you need to track and grow your business- right at your fingertips.</AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Button color="charcoal" bg="blue.400" mt={8} width={['80%', '70%', '60%', '40%']} px={2} onClick={() => setApplicationStep(1)}>
          Apply Now
        </Button>
      </Flex>
      <Flex flexDir="column" flex={[0, 0, 0, 1]} px={[0, 0, 0, 6, 12]} mx={[0, 0, 0, 6, 12]} justifyContent="center" alignItems="center">
        <Image src="/images/auth_landing_graphic.png" objectFit="contain" boxSize={['xl', 'xl', '2xl']} />
      </Flex>
    </Flex>
  );

  const renderHasPendingApplicationView = () => (
    <Flex flexDir="column">
      <Flex bg="blue.400" flex={1} width="100%" justifyContent="space-between" alignItems="center" px={8} py={2} onClick={() => navigate('/')}>
        <Text color="orange.400" fontFamily="BeniBold" fontSize="6xl" letterSpacing={1.8}>
          DONNE
        </Text>
      </Flex>
      <Flex mt={12}>
        <Flex flexDir="column" flex={1} maxW="50%" px={12} mx={12}>
          <Heading color="blue.700" letterSpacing={0.8}>
            Thank you for applying!
          </Heading>
          <Text color="orange.400" my={8} fontSize="xl" fontWeight="bold">
            We will be in touch as soon as possible.
          </Text>
        </Flex>
        <Flex flexDir="column" flex={1} maxW="50%" px={12} mx={12} justifyContent="center" alignItems="center">
          <Image src="/images/auth_landing_graphic.png" />
        </Flex>
      </Flex>
    </Flex>
  );

  const renderProgressBar = () => (
    <>
      <Heading mb={6} color="orange.400" letterSpacing={0.8}>
        Apply to Donne
      </Heading>
      <Flex flex={1} width="80%">
        <Progress flex={1} value={20 * applicationStep} bg="grey.300" colorScheme="orange" borderRadius="lg" />
      </Flex>
      <Flex flex={1} width="100%" mb={8} mt={2} justifyContent="space-evenly">
        <Text fontWeight="bold">Create Account</Text>
        <Text fontWeight="bold">About You</Text>
        <Text fontWeight="bold">Services</Text>
        <Text fontWeight="bold">Experience</Text>
      </Flex>
    </>
  );

  const renderBackNextButtons = () => (
    <Flex mt={8} width={['50%', '50%', '50%', '30%']} flex={1} justifyContent="space-between">
      <Button variant="darkoutline" mr={1} onClick={() => setApplicationStep(applicationStep - 1)}>
        Back
      </Button>
      <Button variant="solid" onClick={() => setApplicationStep(applicationStep + 1)}>
        Continue
      </Button>
    </Flex>
  );

  const renderAccountStep = () => (
    <Flex flexDir="column" width={['99%', '99%', '70%']} alignItems="center">
      {renderProgressBar()}
      <Flex flex={1} width="90%" flexDir="column" borderRadius="lg" borderWidth={1} p={[1, 1, 4, 8]}>
        {/* <Heading mb={6} letterSpacing={0.8}>
          User Account
        </Heading> */}
        {user && currentUser && <Text>You are logged in as {currentUser.username}. Click Continue.</Text>}
        {alreadyHasAccount === undefined && (
          <Flex justifyContent="space-around" flexWrap={['wrap', 'wrap', 'nowrap']}>
            <Button variant="darkoutline" mx={1} my={1} onClick={() => setAlreadyHasAccount(true)}>
              I already have an account
            </Button>
            <Button variant="solid" mx={1} my={1} onClick={() => setAlreadyHasAccount(false)}>
              I need to create an account
            </Button>
          </Flex>
        )}
        {alreadyHasAccount && (
          <Flex flexDir="column">
            <FormControl isRequired isInvalid={invalid}>
              <Input placeholder="Email" autoCapitalize="none" type="email" autoFocus m={2} value={email} onChange={(e: any) => setEmail(e.target.value)} />
              <InputGroup>
                <Input
                  placeholder="Password"
                  autoCapitalize="none"
                  type={pwVisibility ? 'text' : 'password'}
                  m={2}
                  pr={12}
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                />
                <InputRightElement>
                  <ViewIcon m={0} pt={4} pr={2} h={8} w={8} onClick={() => setPwVisibility(!pwVisibility)} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button bg="blue.400" onClick={() => logInWithEmailAndPassword(email, password)} mt="4">
              Continue with email
            </Button>
            <Flex flex={1} width="100%" my={2} flexDir="row" justifyContent="center" alignItems="center">
              <Divider bg="grey.200" width={36} borderWidth={1} />
              <Text as="b" my={2} mx={4}>
                Or
              </Text>
              <Divider bg="grey.200" width={36} borderWidth={1} />
            </Flex>
            <Flex flexDir="column" flex={1} width="100%">
              <Button variant="outline" my={2} onClick={() => signInWithApple()}>
                Sign in with Apple
              </Button>
              <Button variant="outline" my={2} onClick={() => signInWithGoogle()}>
                Sign in with Google
              </Button>
              <Button variant="darkoutline" my={4} onClick={() => setAlreadyHasAccount(undefined)}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        )}
        {alreadyHasAccount !== null && alreadyHasAccount === false && (
          <Flex flexDir="column">
            <FormControl isRequired isInvalid={invalid}>
              <Input placeholder="Email" autoCapitalize="none" type="email" autoFocus m={2} value={email} onChange={(e: any) => setEmail(e.target.value)} />
              <InputGroup>
                <Input
                  placeholder="Password"
                  autoCapitalize="none"
                  type={pwVisibility ? 'text' : 'password'}
                  m={2}
                  pr={12}
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                />
                <InputRightElement>
                  <ViewIcon m={0} pt={4} pr={2} h={8} w={8} onClick={() => setPwVisibility(!pwVisibility)} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button onClick={onHandleSignup}>Sign Up with Email</Button>
            <Flex flex={1} width="100%" my={2} flexDir="row" justifyContent="center" alignItems="center">
              <Divider bg="grey.200" width={36} borderWidth={1} />
              <Text as="b" my={2} mx={4}>
                Or
              </Text>
              <Divider bg="grey.200" width={36} borderWidth={1} />
            </Flex>
            <Flex flexDir="column" flex={1} width="100%">
              <Button variant="outline" my={2} onClick={() => signInWithApple()}>
                Sign in with Apple
              </Button>
              <Button variant="outline" my={2} onClick={() => signInWithGoogle()}>
                Sign in with Google
              </Button>
              <Button variant="outline" my={4} onClick={() => setAlreadyHasAccount(undefined)}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
      {user && renderBackNextButtons()}
    </Flex>
  );

  const renderApplicationStepAboutYou = () => {
    const fname_invalid = applicationData?.applicant.firstname === '' || applicationData?.applicant.firstname === null;
    const lname_invalid = applicationData?.applicant.lastname === '' || applicationData?.applicant.lastname === null;
    const email_invalid = applicationData?.applicant.email === '' || applicationData?.applicant.email === null;
    const phone_invalid = applicationData?.applicant.phone === '' || applicationData?.applicant.phone === null;
    const city_invalid = applicationData?.applicant.city === '' || applicationData?.applicant.city === null || applicationData.applicant.city === undefined;
    const state_invalid =
      applicationData?.applicant.state === '' || applicationData?.applicant.state === null || applicationData?.applicant.state === undefined;
    const zip_invalid =
      applicationData?.applicant.zipcode === '' || applicationData?.applicant.zipcode === null || applicationData?.applicant.state === undefined;

    return (
      <Flex flexDir="column" width={['99%', '99%', '70%']} alignItems="center">
        {renderProgressBar()}
        <Flex flex={1} width="90%" flexDir="column" borderRadius="lg" borderWidth={1} p={[1, 1, 4, 8]}>
          <Text fontWeight="bold" fontSize="2xl" mx={4}>
            About You
          </Text>
          <Flex justifyContent="space-around" flexWrap={['wrap', 'wrap', 'nowrap']}>
            <FormControl mt={4} mx={4} isRequired>
              <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
                First Name
              </FormLabel>
              <Input value={applicationData?.applicant.firstname} onChange={(e) => updateApplicationDataField('applicant.firstname', e.target.value)} />
              {fname_invalid && <FormErrorMessage>First Name is required.</FormErrorMessage>}
            </FormControl>
            <FormControl mt={4} mx={4} isRequired>
              <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
                Last Name
              </FormLabel>
              <Input value={applicationData.applicant.lastname} onChange={(e) => updateApplicationDataField('applicant.lastname', e.target.value)} />
              {lname_invalid && <FormErrorMessage>Last Name is required.</FormErrorMessage>}
            </FormControl>
          </Flex>
          <Flex justifyContent={['flex-start', 'space-around']} flex={1} flexWrap={['wrap', 'wrap', 'nowrap']}>
            <FormControl mt={4} mx={4} isRequired>
              <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
                Email
              </FormLabel>
              <Input type="email" value={applicationData.applicant.email} onChange={(e) => updateApplicationDataField('applicant.email', e.target.value)} />
              {email_invalid && <FormErrorMessage>Email is required.</FormErrorMessage>}
            </FormControl>
            <Flex>
              <FormControl mt={4} mx={4} flex={[1, 1, 3]} isRequired>
                <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
                  Phone
                </FormLabel>
                <Input type="tel" value={applicationData.applicant.phone} onChange={(e) => updateApplicationDataField('applicant.phone', e.target.value)} />
                {phone_invalid && <FormErrorMessage>Phone number is required.</FormErrorMessage>}
              </FormControl>
            </Flex>
          </Flex>
          <Flex justifyContent="space-around" flexWrap={['wrap', 'wrap', 'nowrap']}>
            <FormControl mt={4} ml={4} mr={2} isRequired>
              <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
                City
              </FormLabel>
              <Input
                name="city"
                autoComplete="address-level2"
                value={applicationData.applicant.city}
                onChange={(e) => updateApplicationDataField('applicant.city', e.target.value)}
              />
              {city_invalid && <FormErrorMessage>City is required.</FormErrorMessage>}
            </FormControl>
            <Flex flexWrap={['wrap', 'wrap', 'nowrap']}>
              <FormControl mt={4} mx={2} isRequired>
                <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
                  State
                </FormLabel>
                <Input
                  required
                  autoComplete="address-level1"
                  maxLength={2}
                  value={applicationData.applicant.state}
                  onChange={(e) => updateApplicationDataField('applicant.state', e.target.value)}
                />
                {state_invalid && <FormErrorMessage>State is required.</FormErrorMessage>}
              </FormControl>
              <FormControl mt={4} mx={2} isRequired>
                <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
                  Zipcode
                </FormLabel>
                <Input
                  // type="number"
                  name="postal-code"
                  autoComplete="postal-code"
                  min={10000}
                  max={99999}
                  value={applicationData.applicant.zipcode}
                  onChange={(e) => updateApplicationDataField('applicant.zipcode', e.target.value)}
                />
                {zip_invalid && <FormErrorMessage>Zipcode is required.</FormErrorMessage>}
              </FormControl>
            </Flex>
          </Flex>
          <Flex flexWrap={['wrap', 'wrap', 'nowrap']}>
            <FormControl mt={4} mx={4}>
              <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
                Social (select platform or enter custom)
              </FormLabel>
              <Flex flexWrap={['wrap', 'wrap', 'nowrap']}>
                <MultiSelect
                  create
                  single
                  options={[
                    { label: 'Instagram', value: 'Instagram' },
                    { label: 'TikTok', value: 'TikTok' },
                    { label: 'Facebook', value: 'Facebook' },
                  ]}
                  value={applicationData.applicant.social?.platform || ''}
                  onChange={(e) => updateApplicationDataField('applicant.social.platform', e)}
                />
                <Input
                  type="url"
                  placeholder="Enter URL to the social profile"
                  mt={[1, 0]}
                  value={applicationData.applicant.social?.url}
                  onChange={(e) => updateApplicationDataField('applicant.social.url', e.target.value)}
                />
              </Flex>
            </FormControl>
          </Flex>
        </Flex>
        {renderBackNextButtons()}
      </Flex>
    );
  };

  const renderApplicationStepServices = () => (
    <Flex flexDir="column" width={['99%', '99%', '70%']} alignItems="center">
      {renderProgressBar()}
      <Flex flex={1} width="90%" flexDir="column" borderRadius="lg" borderWidth={1} p={8}>
        <Text fontWeight="bold" fontSize="2xl" mx={4}>
          Services
        </Text>
        <Flex flexDir="column" width={['99%', '90%', '80%', '50%']}>
          <FormControl my={4} mx={4}>
            <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
              Which services do you offer? Select all that apply.
            </FormLabel>
            <MultiSelect
              options={EXPERT_SERVICE_OFFERINGS.filter((o) => o.value !== 'All').map((so) => {
                const smo: Option = {
                  label: so.title,
                  value: so.value,
                };
                return smo;
              })}
              // value={selectedServices}
              // onChange={setSelectedServices}
              style={{ background: 'blue.400' }}
              value={applicationData.services}
              onChange={(e) => updateApplicationDataField('services', e)}
            />
          </FormControl>
          <FormControl mt={4} mx={4}>
            <FormLabel m={0} p={0} mb={2} fontSize="sm" fontWeight="bold">
              Do you offer in person or virtual services (or both)?
            </FormLabel>
            <Checkbox
              colorScheme="orange"
              isChecked={applicationData.doesInPerson}
              ml={2}
              onChange={(e) => updateApplicationDataField('doesInPerson', e.target.checked)}
            >
              In Person
            </Checkbox>
            <Checkbox
              ml={6}
              colorScheme="orange"
              // iconColor="blue.400"
              isChecked={applicationData.doesVirtual}
              onChange={(e) => updateApplicationDataField('doesVirtual', e.target.checked)}
            >
              Virtual
            </Checkbox>
          </FormControl>
        </Flex>
      </Flex>
      {renderBackNextButtons()}
    </Flex>
  );

  const renderApplicationStepExperience = () => (
    <Flex flexDir="column" width={['99%', '99%', '70%']} alignItems="center">
      {renderProgressBar()}
      <Flex flex={1} width="90%" flexDir="column" borderRadius="lg" borderWidth={1} p={8}>
        <Text fontWeight="bold" fontSize="2xl" mx={4}>
          Experience
        </Text>
        <Flex flexDir="column" width={['90%', '90%', '80%', '50%']}>
          <FormControl mt={4} mx={4}>
            <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
              How many years of experience do you have?
            </FormLabel>
            <Input
              type="number"
              min={0}
              value={applicationData.experience.yearsexperience}
              onChange={(e) => updateApplicationDataField('experience.yearsexperience', e.target.value)}
            />
          </FormControl>
          <FormControl mt={4} mx={4}>
            <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
              How are you currently finding and engagement clients?
            </FormLabel>
            <Textarea
              borderWidth={0.5}
              borderColor="inherit"
              value={applicationData.experience.currentpractice}
              onChange={(e) => updateApplicationDataField('experience.currentpractice', e.target.value)}
            />
          </FormControl>
          <FormControl mt={4} mx={4}>
            <FormLabel m={0} p={0} fontSize="sm" fontWeight="bold">
              Website or Portfolio link:
            </FormLabel>
            <Input
              type="url"
              value={applicationData.experience.websitelink}
              onChange={(e) => updateApplicationDataField('experience.websitelink', e.target.value)}
            />
          </FormControl>
        </Flex>
      </Flex>
      {renderBackNextButtons()}
    </Flex>
  );

  const renderApplicationSummary = () => {
    const clone = applicationData;
    // console.log(clone);
    if (!clone) return <Box />;
    const locationDisplay =
      clone.doesInPerson && clone.doesVirtual
        ? 'Virtual & In Person'
        : clone.doesInPerson && !clone.doesVirtual
        ? 'In Person Only'
        : !clone.doesInPerson && clone.doesVirtual
        ? 'Virtual Only'
        : 'None.';
    return (
      <Flex flexDir="column" flex={1} width="70%" alignItems="center" justifyContent="flex-start">
        <Flex justifyContent="center" my={4} width="80%">
          <Text fontWeight="bold" letterSpacing={0.8} fontSize={['3xl', '3xl', '3xl', '4xl', '4xl']} mx={4} fontFamily="BeniBold" color="orange.400">
            Review Application
          </Text>
        </Flex>
        <Flex flex={1} width={['95%', '90%', '90%', '90%', '80%']} flexWrap={['wrap', 'wrap', 'nowrap']}>
          <Flex flex={1} flexDir="column" borderWidth={1} p={8} mr={2} mt={[2, 0]} flexWrap={['wrap', 'wrap', 'nowrap']}>
            <Flex mb={4} justifyContent="space-between" alignItems="center" flexWrap={['wrap', 'wrap', 'nowrap']}>
              <Text fontWeight="bold" fontSize="2xl">
                About You
              </Text>
              <EditIcon color="blue.600" onClick={() => setApplicationStep(2)} />
            </Flex>
            <Flex mb={4} flexWrap={['wrap', 'wrap', 'nowrap']}>
              <LabelValueDisplayItem fieldname="First Name" fieldvalue={applicationData.applicant?.firstname} vertical />
              <LabelValueDisplayItem fieldname="Last Name" fieldvalue={applicationData.applicant?.lastname} vertical />
            </Flex>
            <Flex mb={4} flexWrap={['wrap', 'wrap', 'nowrap']}>
              <LabelValueDisplayItem fieldname="Email" fieldvalue={applicationData.applicant?.email} vertical />
              <LabelValueDisplayItem fieldname="Phone" fieldvalue={applicationData.applicant?.phone} vertical />
            </Flex>
            <Flex mb={4} flexWrap={['wrap', 'wrap', 'nowrap']}>
              <LabelValueDisplayItem fieldname="City" fieldvalue={applicationData.applicant?.city} vertical />
              <LabelValueDisplayItem fieldname="State" fieldvalue={applicationData.applicant?.state} vertical />
              <LabelValueDisplayItem fieldname="Zip" fieldvalue={applicationData.applicant?.zipcode} vertical />
            </Flex>
            <Flex flexDir="column">
              <Text color="orange.400" fontWeight={500}>
                Social:
              </Text>
              <LabelValueDisplayItem fieldname={applicationData.applicant?.social?.platform as string} fieldvalue={applicationData.applicant?.social?.url} />
            </Flex>
          </Flex>
          <Flex flex={1} flexDir="column" mt={[2, 0]}>
            <Flex flex={1} flexDir="column" borderWidth={1} p={8} mb={2}>
              <Flex mb={4} justifyContent="space-between" alignItems="center">
                <Text fontWeight="bold" fontSize="2xl">
                  Services
                </Text>
                <EditIcon color="blue.600" onClick={() => setApplicationStep(3)} />
              </Flex>
              <Flex mb={4}>
                <LabelValueDisplayItem fieldname="Which services will you provide?" fieldvalue={applicationData.services?.join(', ')} vertical />
              </Flex>
              <Flex mb={1}>
                <LabelValueDisplayItem fieldname="Do you offer in person and virtual?" fieldvalue={locationDisplay} vertical />
              </Flex>
            </Flex>
            <Flex flex={1} flexDir="column" borderWidth={1} p={8}>
              <Flex mb={4} justifyContent="space-between" alignItems="center">
                <Text fontWeight="bold" fontSize="2xl">
                  Experience
                </Text>
                <EditIcon color="blue.600" onClick={() => setApplicationStep(4)} />
              </Flex>
              <Flex mb={4}>
                <LabelValueDisplayItem fieldname="How many years experience do you have?" fieldvalue={applicationData.experience?.yearsexperience} vertical />
              </Flex>
              <Flex mb={4}>
                <LabelValueDisplayItem
                  fieldname="How are you currently finding and engaging clients?"
                  fieldvalue={applicationData.experience?.currentpractice}
                  vertical
                />
              </Flex>
              <LabelValueDisplayItem fieldname="Website or Portfolio link" fieldvalue={applicationData.experience?.websitelink} vertical />
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" my={6} width="80%" mt={[2, 0]}>
          <Button variant="solid" onClick={handleSubmitApplication}>
            Submit Application
          </Button>
        </Flex>
      </Flex>
    );
  };

  if (currentProfessional) {
    navigate('/home');
  }

  if (hasExistingApplication) return renderHasPendingApplicationView();

  return (
    <Flex flexDir="column">
      <Flex bg="blue.400" width="100%" justifyContent="space-between" alignItems="center" px={8} py={1} onClick={() => navigate('/')}>
        <Text color="orange.400" fontFamily="BeniBold" fontSize="6xl">
          DONNE
        </Text>
        {user && applicationStep > 1 && (
          <Text
            fontSize="small"
            onClick={() => {
              setApplicationData({
                timestamp: new Date(),
                applicant: {},
                services: [],
                experience: {},
              });
              setCurrentProfessional(undefined);
              setCurrentUser(undefined);
              logout();
              navigate('/login');
            }}
          >
            Logout
          </Text>
        )}
      </Flex>

      <Flex flex={1} justifyContent="center" py={[2, 2, 3, 4, 4]} fontFamily="body">
        {applicationStep === 0 && renderLandingView()}
        {applicationStep === 1 && renderAccountStep()}
        {applicationStep === 2 && renderApplicationStepAboutYou()}
        {applicationStep === 3 && renderApplicationStepServices()}
        {applicationStep === 4 && renderApplicationStepExperience()}
        {applicationStep === 5 && renderApplicationSummary()}
      </Flex>
    </Flex>
  );
}

export default ApplicationScreen;
