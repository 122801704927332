/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import {
  Box,
  Text,
  Heading,
  Flex,
  Select,
  Button,
  Image as ChakraImage,
  Grid,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Textarea,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
  Icon,
  InputGroup,
  InputLeftElement,
  PopoverFooter,
  chakra,
  Avatar,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon, PlusSquareIcon, SearchIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { ImEnlarge2, ImShrink2 } from 'react-icons/im';
import { FaArrowRotateRight } from 'react-icons/fa6';
import { MultiSelect } from 'chakra-multiselect';
// import { CUIAutoComplete } from 'chakra-ui-autocomplete';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useFilePicker } from 'use-file-picker';
// import html2canvas from 'html2canvas';
import { toPng } from 'html-to-image';
import { RiDeleteBin2Line } from 'react-icons/ri';
import AddIcon from '../components/AddIcon';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { Client } from '../models/Client';
import { fetchClientForProfessional } from '../services/ProfessionalsService';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import UserMediaAssetDisplay from '../components/UserMediaAssetDisplay';
import LoadingScreen from '../components/LoadingScreen';
import { WardrobeItemCategory, WardrobeItemSeasonality, WardrobeItemSubCategories } from '../models/WardrobeItemCategory';
import { defaultWardrobeItemQuery, WardrobeItemQuery } from '../models/WardrobeItemQuery';
import {
  createWardrobeItemFromModel,
  deleteWardrobeItem,
  fetchWardrobeItem,
  fetchWardrobeSubcategories,
  searchWardrobeItems,
  updateWardrobeItem,
  uploadNewWardrobeItem,
  uploadNewWardrobeItemForClient,
} from '../services/WardrobeItemsService';
import { PaginatedResult } from '../models/PaginatedResult';
import { WardrobeItem, WardrobeItemUploadDTO } from '../models/WardrobeItem';
import { defaultOutfitQuery, OutfitQuery } from '../models/OutfitQuery';
import { createOutfit, deleteOutfit, searchOutfits, updateOutfit } from '../services/OutfitsService';
import { Outfit } from '../models/Outfit';
import { createLookBook, deleteLookBook, searchLookBooks, updateLookBook } from '../services/LookBooksService';
import { defaultLookBookQuery, LookBookQuery } from '../models/LookBookQuery';
import { LookBook } from '../models/LookBook';
import OutfitPartsDisplay from '../components/OutfitPartsDisplay';
import { OutfitPart } from '../models/OutfitPart';
import { OutfitDecorator } from '../models/OutfitDecorator';
import useDebounce from '../hooks/useDebounce';
import Pagination from '../components/Pagination';
import { OUTFIT_DISPLAY_HEIGHT } from '../config/ServiceConstants';
import { OutfitPartsEditor } from '../components/OutfitPartsEditor';
import { CreateMessageDTO } from '../models/Message';
import { createMessage } from '../services/MessagesService';
import { UserQuery, UserSearchScope } from '../models/UserQuery';
import { searchUsers } from '../services/UsersService';
import UserSearchControl from '../components/UserSearchControl';
import '../theme/chakra-overrides.css';

type PrimaryCategorySelectorProps = {
  value?: string | number | readonly string[] | undefined;
  handleSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export function PrimaryCategorySelector({ value, handleSelection }: PrimaryCategorySelectorProps) {
  return (
    <Select variant="flushed" value={value} onChange={handleSelection}>
      <option value="all">All</option>
      <option value="tops">Tops</option>
      <option value="bottoms">Bottoms</option>
      <option value="dress">Dress</option>
      <option value="shoes">Shoes</option>
      <option value="accessories">Accessories</option>
    </Select>
  );
}

function ClientWardrobeScreen() {
  const { currentProfessional } = useContext(CurrentProfessionalContext);
  const [user, loading, error] = useAuthState(auth);
  const [loadingData, setLoadingData] = useState(false);
  const { state } = useLocation();
  const { clientuserid } = state;
  const [client, setClient] = useState<Client | undefined>();
  const toast = useToast();
  // const printRef = React.useRef() as React.MutableRefObject<any>; // HTMLDivElement
  //
  const [selectedEntityType, setSelectedEntityType] = useState('Items'); // Items, Outfits, LookBooks
  const [showFilters, setShowFilters] = useState(false);
  const [sortExpression, setSortExpression] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1200);
  const PAGE_SIZE = 40;
  const [shareMessage, setShareMessage] = useState<CreateMessageDTO | undefined>();
  //
  const [wardrobeItemsQuery, setWardrobeItemsQuery] = useState<WardrobeItemQuery>({ ...defaultWardrobeItemQuery, userid: clientuserid });
  const [wardrobeItemsQueryWIP, setWardrobeItemsQueryWIP] = useState<WardrobeItemQuery>({ ...defaultWardrobeItemQuery, userid: clientuserid }); // used by filter component
  const [selectedWardrobeItem, setSelectedWardrobeItem] = useState<WardrobeItem | undefined>();
  const [wardrobeItems, setWardrobeItems] = useState<WardrobeItem[]>([]);
  const [totalWardrobeItems, setTotalWardrobeItems] = useState<number>(0);
  const [showNewWardrobeItemModal, setShowNewWardrobeItemModal] = useState(false); // controls visibility of first Add Item modal
  const [newWardrobeItem, setNewWardrobeItem] = useState<WardrobeItemUploadDTO>({} as WardrobeItemUploadDTO); // used for new item details modal
  const [itemsSelectedForOutfit, setItemsSelectedForOutfit] = useState<WardrobeItem[] | undefined>();
  const [subcategoriesForUser, setSubcategoriesForUser] = useState<string[]>([]);
  const shareItemRef = React.useRef<any[]>([]);
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const cancelRef = React.useRef<any>();
  //
  const [showCreateOutfitView, setShowCreateOutfitView] = useState(false);
  const [textToAddToOutfit, setTextToAddToOutfit] = useState('');
  const [selectedOutfitPart, setSelectedOutfitPart] = useState<OutfitPart | OutfitDecorator | undefined>();
  const [outfits, setOutfits] = useState<Outfit[]>([]);
  const [totalOutfits, setTotalOutfits] = useState<number>(0);
  const [selectedOutfit, setSelectedOutfit] = useState<Outfit | undefined>();
  const [outfitsSelectedForLookBook, setOutfitsSelectedForLookBook] = useState<Outfit[] | undefined>();
  const [outfitQuery, setOutfitQuery] = useState<OutfitQuery>({ ...defaultOutfitQuery, userid: clientuserid });
  const [outfitQueryWIP, setOutfitQueryWIP] = useState<OutfitQuery>({ ...defaultOutfitQuery, userid: clientuserid });
  const shareOutfitRef = React.useRef<any[]>([]);
  //
  const [lookBooks, setLookBooks] = useState<LookBook[]>([]);
  const [totalLookBooks, setTotalLookBooks] = useState<number>(0);
  const [showCreateLookBookView, setShowCreateLookBookView] = useState(false);
  const [showViewLookBookDetailsModal, setShowViewLookBookDetailsModal] = useState(false);
  const [selectedLookBook, setSelectedLookBook] = useState<LookBook | undefined>();
  const [lookBookQuery, setLookBookQuery] = useState<LookBookQuery>({ ...defaultLookBookQuery, userid: clientuserid });
  const [lookBookQueryWIP, setLookBookQueryWIP] = useState<LookBookQuery>({ ...defaultLookBookQuery, userid: clientuserid });
  const shareLookBookRef = React.useRef<any[]>([]);

  const [openFileSelector, { filesContent, plainFiles, loading: fileLoading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    // maxFileSize: 20,
    // imageSizeRestrictions: {
    //   maxHeight: 5000, // in pixels
    //   maxWidth: 5000,
    //   minHeight: 20,
    //   minWidth: 20,
    // },
  });

  useEffect(() => {
    if (!user) return;
    const fetchClient = async () => {
      await fetchClientForProfessional(user, clientuserid)
        .then((res) => res.json())
        .then((result: Client) => {
          setClient(result);
        });
    };
    fetchClient().catch(console.error);
  }, [clientuserid, user]);

  const fetchClientWardrobeItems = async () => {
    if (!user) return;
    if (!clientuserid) return;
    if (selectedEntityType !== 'Items') return;

    const query: WardrobeItemQuery = structuredClone(wardrobeItemsQuery);
    if (sortExpression) query.sf = sortExpression;
    if (sortDirection) query.sd = sortDirection;
    if (debouncedSearchTerm) query.searchterm = debouncedSearchTerm;
    const clientWardrobeId = 0; // client.user.wardrobeid
    await searchWardrobeItems(user, clientWardrobeId, query)
      .then((res) => res.json())
      .then((result: PaginatedResult) => {
        setWardrobeItems(result.pageItems);
        setTotalWardrobeItems(result.totalItemsAvailable);
        setLoadingData(false);
      });
  };

  useEffect(() => {
    if (!user) return;
    if (!clientuserid) return;
    if (selectedEntityType !== 'Items') return;

    // const query: WardrobeItemQuery = structuredClone(wardrobeItemsQuery);
    // if (sortExpression) query.sf = sortExpression;
    // if (sortDirection) query.sd = sortDirection;
    // if (debouncedSearchTerm) query.searchterm = debouncedSearchTerm;
    // const clientWardrobeId = 0; // client.user.wardrobeid

    // const fetchClientWardrobeItems = async () => {
    //   setLoadingData(true);
    //   await searchWardrobeItems(user, clientWardrobeId, query)
    //     .then((res) => res.json())
    //     .then((result: PaginatedResult) => {
    //       setWardrobeItems(result.pageItems);
    //       setTotalWardrobeItems(result.totalItemsAvailable);
    //       setLoadingData(false);
    //     });
    // };
    fetchClientWardrobeItems().catch((err) => {
      console.log(err);
      setLoadingData(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, selectedEntityType, wardrobeItemsQuery, sortExpression, sortDirection, debouncedSearchTerm]);

  useEffect(() => {
    if (!user) return;
    if (!clientuserid) return;
    if (selectedEntityType !== 'Outfits') return;

    const query = structuredClone(outfitQuery);
    if (sortExpression) query.sf = sortExpression;
    if (sortDirection) query.sd = sortDirection;
    if (debouncedSearchTerm) query.searchterm = debouncedSearchTerm;

    const fetchOutfits = async () => {
      setLoadingData(true);
      await searchOutfits(user, query)
        .then((res) => res.json())
        .then((result: PaginatedResult) => {
          console.log(result.pageItems);
          setOutfits(result.pageItems);
          setTotalOutfits(result.totalItemsAvailable);
          setLoadingData(false);
        });
    };
    fetchOutfits().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, selectedEntityType, outfitQuery, sortExpression, sortDirection, debouncedSearchTerm]);

  useEffect(() => {
    if (!user) return;
    if (!clientuserid) return;
    if (selectedEntityType !== 'LookBooks') return;

    const query = structuredClone(lookBookQuery);
    if (sortExpression) query.sf = sortExpression;
    if (sortDirection) query.sd = sortDirection;
    if (debouncedSearchTerm) query.searchterm = debouncedSearchTerm;

    const fetchLookBooks = async () => {
      await searchLookBooks(user, query)
        .then((res) => res.json())
        .then((result: PaginatedResult) => {
          setLookBooks(result.pageItems);
          setTotalLookBooks(result.totalItemsAvailable);
          console.log('fetched: ', result.totalItemsAvailable, ' LookBooks.');
        });
    };
    fetchLookBooks().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, selectedEntityType, lookBookQuery, sortExpression, sortDirection, debouncedSearchTerm]);

  useEffect(() => {
    if (!user) return;
    if (!clientuserid) return;

    const fetchUserSubcategories = async () => {
      await fetchWardrobeSubcategories(user, clientuserid)
        .then((res) => res.json())
        .then((result: string[]) => {
          result.unshift('All');
          setSubcategoriesForUser(result);
        });
    };
    fetchUserSubcategories().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!client) return <LoadingScreen />;

  const renderEntityTabButtons = () => (
    <Flex justifyContent="flex-start" my={4} fontWeight="bold">
      <Box
        px={4}
        ml={2}
        borderRadius="xl"
        borderColor="blue.400"
        borderWidth={1}
        bg={selectedEntityType === 'Items' ? 'blue.400' : 'white'}
        color={selectedEntityType === 'Items' ? 'white' : 'charcoal'}
        onClick={() => setSelectedEntityType('Items')}
      >
        Items
      </Box>
      <Box
        px={4}
        ml={2}
        borderRadius="xl"
        borderColor="blue.400"
        borderWidth={1}
        bg={selectedEntityType === 'Outfits' ? 'blue.400' : 'white'}
        color={selectedEntityType === 'Outfits' ? 'white' : 'charcoal'}
        onClick={() => setSelectedEntityType('Outfits')}
      >
        Outfits
      </Box>
      <Box
        px={4}
        ml={2}
        borderRadius="xl"
        borderColor="blue.400"
        borderWidth={1}
        bg={selectedEntityType === 'LookBooks' ? 'blue.400' : 'white'}
        color={selectedEntityType === 'LookBooks' ? 'white' : 'charcoal'}
        onClick={() => setSelectedEntityType('LookBooks')}
      >
        LookBooks
      </Box>
    </Flex>
  );

  const renderShareMessageModal = () => (
    <Modal size="lg" isCentered isOpen={shareMessage !== undefined} onClose={() => setShareMessage(undefined)}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>Share</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" my={3} width="80%">
            <Flex mb={4} flex={1} flexDir="column">
              <Text mr={4} mb={1}>
                Choose recipient(s)
              </Text>
              <UserSearchControl
                value={shareMessage?.recipients}
                allowMultiple
                handleSelection={(v) => {
                  console.log('selected: ', v);
                  setShareMessage({ ...shareMessage, recipients: v.map((usr) => usr.userid) } as CreateMessageDTO);
                }}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Message</Text>
              <Textarea
                value={shareMessage?.messageBody}
                onChange={(e) => setShareMessage({ ...shareMessage, messageBody: e.target.value } as CreateMessageDTO)}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <ChakraImage src={shareMessage?.imageDataUrl} />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="darkoutline" onClick={() => setShareMessage(undefined)}>
            Cancel
          </Button>
          <Button
            ml={6}
            variant="solid"
            onClick={() => {
              if (!shareMessage) return;
              createMessage(user as UserAuth, shareMessage).then(() => {
                toast({
                  title: 'Message sent',
                  description: 'Message sent successfully.',
                  status: 'success',
                  duration: 4000,
                  isClosable: true,
                });
                setShareMessage(undefined);
              });
            }}
          >
            Send Message
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const handleNewWardrobeItemCreate = async () => {
    newWardrobeItem.image = plainFiles[0];
    newWardrobeItem.userid = clientuserid;
    newWardrobeItem.createdby = currentProfessional.userid;
    await uploadNewWardrobeItemForClient(user as UserAuth, newWardrobeItem as WardrobeItemUploadDTO)
      .then((res) => res.json())
      .then((result) => result.id)
      .then((id) => {
        // total hack, trying to wait for processing to be completed to add the item locally
        const fetchWI = async () => {
          await fetchWardrobeItem(user as UserAuth, 0, id)
            .then((res) => res.json())
            .then((wi: WardrobeItem) => {
              const clone = structuredClone(wardrobeItems);
              clone.unshift(wi);
              setWardrobeItems(clone);
            });
        };
        setTimeout(fetchWI, 5600);
        toast({
          title: 'Item Saved',
          description: 'Item will appear momentarily.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      })
      .then((r) => {
        setNewWardrobeItem({} as WardrobeItemUploadDTO);
        clearFilePicker();
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateWardrobeItemForUser = async (item: WardrobeItem) => {
    await updateWardrobeItem(user as UserAuth, item)
      .then(() => {
        const clone: WardrobeItem[] = structuredClone(wardrobeItems);
        const indx = clone.indexOf(item);
        if (indx > -1) clone[indx] = item;
        setWardrobeItems(clone);
      })
      .then(() => setSelectedWardrobeItem(undefined));
  };

  const handleSelectItemForOutfit = (item: WardrobeItem) => {
    let clone = structuredClone(itemsSelectedForOutfit);
    if (!clone) clone = [];
    clone.push(item);
    setItemsSelectedForOutfit(clone);
  };

  const handleRemoveItemSelectedForOutfit = (index: number) => {
    let clone: WardrobeItem[] = structuredClone(itemsSelectedForOutfit);
    if (!clone) clone = [];
    clone.splice(index, 1);
    setItemsSelectedForOutfit(clone);
  };

  const renderWardrobeItem = (item: WardrobeItem, i: number) => {
    const handleShareWardrobeItem = () => {
      if (!shareItemRef.current) return;
      toPng(shareItemRef.current[i], { cacheBust: true })
        .then((dataUrl) => {
          const msg: CreateMessageDTO = {
            messageBody: '',
            imageDataUrl: dataUrl,
            recipients: [],
          };
          setShareMessage(msg); // this will trigger the modal
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return (
      <Flex key={item.id} flexDir="column" borderRadius="lg" borderColor="grey.300" borderWidth={1} p={4} justifyContent="flex-start">
        <Flex justifyContent="space-between">
          <Text bg={item.createdby ? 'olive.400' : 'yellow.400'} color={item.createdby ? 'white' : 'charcoal'} px={2} py={1} borderRadius="lg" fontSize="xs">
            {item.createdby ? 'Recommended' : 'Owned'}
          </Text>
          <Flex>
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <Text color="blue.400" px="2" fontWeight="bold" fontSize="lg">
                  ...
                </Text>
              </PopoverTrigger>
              <PopoverContent bg="white" borderWidth={1} borderColor="black" mt={2} maxWidth="14rem">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Flex flexDir="column" m={1}>
                    <Text my={1} onClick={handleShareWardrobeItem}>
                      Share item
                    </Text>
                    <Text my={1} onClick={() => setSelectedWardrobeItem(item)}>
                      Edit item
                    </Text>
                    <Text my={1} onClick={() => handleSelectItemForOutfit(item)}>
                      Add to outfit
                    </Text>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
        <Flex ref={(el) => (shareItemRef.current[i] = el)}>
          <UserMediaAssetDisplay
            uri={item.url}
            type="image"
            height="20rem"
            width="20rem"
            rotation={item.degreesToRotate ? item.degreesToRotate : undefined}
            // bg="pink"
          />
        </Flex>
        <Flex justifyContent="flex-end">
          <AddIcon onClick={() => handleSelectItemForOutfit(item)} />
        </Flex>
      </Flex>
    );
  };

  const renderAddWardrobeItemModal = () => (
    <Modal size="md" isCentered isOpen={showNewWardrobeItemModal} onClose={() => setShowNewWardrobeItemModal(false)}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>Add Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" my={3} width="80%">
            <Flex mb={4} flex={1} flexDir="column">
              <Text fontSize="xs" mr={4} mb={1}>
                Upload Image
              </Text>
              <Button variant="solid" onClick={() => openFileSelector()}>
                Select Image
              </Button>
              {loading && <Spinner />}
              {errors && errors.length > 0 && <Text>Error: file size too large.</Text>}
            </Flex>
            {filesContent && filesContent[0] && <UserMediaAssetDisplay uri={filesContent[0].content} type="image" height={64} width={64} />}
            <Flex flexDir="column" my={2}>
              <Text>Link to Purchase</Text>
              <Input
                value={newWardrobeItem?.retailurl}
                onChange={(e) => setNewWardrobeItem({ ...newWardrobeItem, retailurl: e.target.value } as WardrobeItemUploadDTO)}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Note to Client</Text>
              <Input
                value={newWardrobeItem?.creatornotes}
                onChange={(e) => setNewWardrobeItem({ ...newWardrobeItem, creatornotes: e.target.value } as WardrobeItemUploadDTO)}
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="darkoutline" onClick={() => setShowNewWardrobeItemModal(false)}>
            Cancel
          </Button>
          <Button
            ml={6}
            variant="solid"
            onClick={() => {
              handleNewWardrobeItemCreate();
              setShowNewWardrobeItemModal(false);
            }}
          >
            Add Item
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const handleDeleteWardrobeItem = async (wardrobeItem: WardrobeItem) => {
    if (!user) return;
    await deleteWardrobeItem(user, wardrobeItem)
      .then((result) => {
        fetchClientWardrobeItems();
        setSelectedWardrobeItem(undefined);
      })
      .catch((err) => console.log(err));
  };

  const handleRotateWardrobeItem = () => {
    if (!selectedWardrobeItem) return;
    const clone: WardrobeItem = structuredClone(selectedWardrobeItem);
    if (!clone.degreesToRotate) clone.degreesToRotate = 0;
    clone.degreesToRotate += 45;
    setSelectedWardrobeItem(clone);
  };

  const renderWardrobeItemDetailsDrawer = () => (
    <Drawer placement="right" size="lg" onClose={() => setSelectedWardrobeItem(undefined)} isOpen={selectedWardrobeItem !== undefined}>
      <DrawerOverlay />
      <DrawerContent bg="white">
        <DrawerHeader borderBottomWidth="1px">
          <Text variant="header" color="charcoal">
            Item Details
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <Flex flexDir="column" justifyContent="center">
            <Flex flexDir="column" alignItems="center">
              <UserMediaAssetDisplay
                uri={selectedWardrobeItem?.url as string}
                rotation={selectedWardrobeItem?.degreesToRotate ? selectedWardrobeItem.degreesToRotate : undefined}
                type="image"
                height="12rem"
                width="12rem"
              />
              <Flex alignItems="center">
                <Text mt={1} mr={2}>
                  {selectedWardrobeItem?.createdby ? 'Recommended' : 'Owned'}
                </Text>
                <Icon as={FaArrowRotateRight} color="blue.400" h={4} w={4} onClick={() => handleRotateWardrobeItem()} />
              </Flex>
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text fontSize="md" fontWeight="bold">
                ITEM CATEGORY
              </Text>
              <PrimaryCategorySelector
                value={selectedWardrobeItem?.primarycategory}
                handleSelection={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, primarycategory: e.target.value } as WardrobeItem)}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text fontSize="md" fontWeight="bold">
                SUBCATEGORY
              </Text>
              <Select
                variant="flushed"
                value={selectedWardrobeItem?.subcategory}
                onChange={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, subcategory: e.target.value } as WardrobeItem)}
              >
                {WardrobeItemSubCategories.filter((c) => c !== 'All').map((sc, i) => (
                  <option key={i.toString()} value={sc}>
                    {sc}
                  </option>
                ))}
              </Select>
            </Flex>
            <Text fontSize="md" fontWeight="bold" my={2}>
              DETAILS
            </Text>
            <Flex flexDir="column" my={2}>
              <Text>Brand</Text>
              <Input
                value={selectedWardrobeItem?.brand}
                onChange={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, brand: e.target.value } as WardrobeItem)}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text fontSize="md" fontWeight="bold">
                Labels
              </Text>
              <MultiSelect
                create
                multiple
                options={selectedWardrobeItem && selectedWardrobeItem.labels ? selectedWardrobeItem.labels.map((lbl) => ({ label: lbl, value: lbl })) : []}
                value={selectedWardrobeItem?.labels || undefined}
                onChange={(e) => {
                  const clone: WardrobeItem = structuredClone(selectedWardrobeItem);
                  clone.labels = e as string[];
                  setSelectedWardrobeItem(clone);
                }}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text fontSize="md" fontWeight="bold">
                Seasonality
              </Text>
              <Select
                variant="flushed"
                value={selectedWardrobeItem?.seasonality}
                onChange={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, seasonality: e.target.value } as WardrobeItem)}
              >
                {WardrobeItemSeasonality.map((sc, i) => (
                  <option key={i.toString()} value={sc}>
                    {sc}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Size</Text>
              <Input
                value={selectedWardrobeItem?.size}
                onChange={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, size: e.target.value } as WardrobeItem)}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Fit</Text>
              <Input
                value={selectedWardrobeItem?.fit}
                onChange={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, fit: e.target.value } as WardrobeItem)}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Color</Text>
              <Input
                value={selectedWardrobeItem?.maincolor}
                onChange={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, maincolor: e.target.value } as WardrobeItem)}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text fontSize="md" fontWeight="bold">
                Tags
              </Text>
              <MultiSelect
                create
                multiple
                options={selectedWardrobeItem && selectedWardrobeItem.tags ? selectedWardrobeItem.tags.map((tag) => ({ label: tag, value: tag })) : []}
                value={selectedWardrobeItem?.tags || undefined}
                onChange={(e) => {
                  const clone: WardrobeItem = structuredClone(selectedWardrobeItem);
                  clone.tags = e as string[];
                  setSelectedWardrobeItem(clone);
                }}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Link</Text>
              <Input
                value={selectedWardrobeItem?.retailurl}
                onChange={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, retailurl: e.target.value } as WardrobeItem)}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Notes</Text>
              <Input
                value={selectedWardrobeItem?.creatornotes}
                onChange={(e) => setSelectedWardrobeItem({ ...selectedWardrobeItem, creatornotes: e.target.value } as WardrobeItem)}
              />
            </Flex>
          </Flex>
        </DrawerBody>
        <DrawerFooter>
          <Flex flex={1} mx={6} justifyContent="space-between">
            <Button mr={3} variant="darkoutline" onClick={() => setSelectedWardrobeItem(undefined)}>
              Cancel
            </Button>
            {selectedWardrobeItem?.createdby === currentProfessional.userid && (
              <>
                <Button variant="solid" bg="highlight.red" onClick={onOpenDelete}>
                  Delete
                </Button>
                <AlertDialog size="md" motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onCloseDelete} isOpen={isOpenDelete} isCentered>
                  <AlertDialogOverlay />
                  <AlertDialogContent>
                    <AlertDialogHeader>Confirm Delete</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>Are you sure you want to delete this?</AlertDialogBody>
                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onCloseDelete}>
                        Cancel
                      </Button>
                      <Button colorScheme="red" ml={3} onClick={() => handleDeleteWardrobeItem(selectedWardrobeItem as WardrobeItem)}>
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </>
            )}
            <Button variant="solid" onClick={() => handleSelectItemForOutfit(selectedWardrobeItem as WardrobeItem)}>
              Add to Outfit
            </Button>
            <Button variant="solid" onClick={() => handleUpdateWardrobeItemForUser(selectedWardrobeItem as WardrobeItem)}>
              Save Changes
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );

  const constructAndShowNewOutfitView = () => {
    // create shell outfit from selected items; or, modify items on existing
    if (!selectedOutfit) {
      const outfit: Outfit = {
        id: 0,
        createdby: currentProfessional.userid,
        userid: clientuserid,
        height: OUTFIT_DISPLAY_HEIGHT,
        width: OUTFIT_DISPLAY_HEIGHT,
        decorators: [],
        parts: [],
      };

      itemsSelectedForOutfit?.forEach((item, i) => {
        const part: OutfitPart = {
          id: i,
          type: 'wardrobeitem',
          wardrobeItemId: item.id,
          imageUrl: item.url,
          height: 120, // hmm, need to think this through
          width: 120, // hmm, need to think this through
          top: 0,
          left: 0,
          rotation: 0,
          sortorder: i,
        };
        outfit.parts.push(part);
      });
      setSelectedOutfit(outfit);
      setShowCreateOutfitView(true);
    } else {
      // editing items on existing outfit
      const clone: Outfit = structuredClone(selectedOutfit);
      itemsSelectedForOutfit?.forEach((item, i) => {
        const copyOfParts: OutfitPart[] = structuredClone(clone.parts);
        const existing = copyOfParts.filter((p) => p.wardrobeItemId === item.id);
        if (!existing || existing.length === 0) {
          const part: OutfitPart = {
            id: i,
            type: 'wardrobeitem',
            wardrobeItemId: item.id,
            imageUrl: item.url,
            height: 160, // hmm, need to think this through
            width: 120, // hmm, need to think this through
            top: 0,
            left: 0,
            rotation: 0,
            sortorder: i,
          };
          clone.parts.push(part);
        }
      });
      setSelectedOutfit(clone);
      setShowCreateOutfitView(true);
    }
  };

  const renderItemsSelectedForOutfit = () => (
    <Flex h={48} width="100%" flex={1} bg="rgba(0, 0, 0, 0.48)" p={4} position="fixed" bottom={0}>
      <Flex zIndex={2000} p={4} backdropBlur="none">
        {itemsSelectedForOutfit &&
          itemsSelectedForOutfit.map((item, i) => (
            <Box key={i.toString()} bg="white" rounded="lg" opacity={1} p={4} mr={4}>
              <SmallCloseIcon
                position="absolute"
                transform="translateY(-10px) translateX(-10px)"
                color="black"
                onClick={() => handleRemoveItemSelectedForOutfit(i)}
              />
              <ChakraImage key={item.id} src={item.thumbnailurl} h={24} w={24} />
            </Box>
          ))}
        <Flex flexDir="column" justifyContent="space-around" ml={8}>
          <Button variant="solid" onClick={constructAndShowNewOutfitView}>
            Create Outfit
          </Button>
          <Button variant="darkoutline" onClick={() => setItemsSelectedForOutfit(undefined)}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );

  const renderCreateOutfitModal = () => {
    const handleAddNewDecoratorToOutfit = () => {
      if (!selectedOutfit) return;
      const clone: Outfit = structuredClone(selectedOutfit);
      if (!clone.decorators) clone.decorators = [];
      const newdecorator: OutfitDecorator = {
        id: clone.decorators.length === 0 ? 1 : Math.max(...clone.decorators.map((p) => p.id)) + 1,
        type: 'decorator',
        content: textToAddToOutfit || '',
        size: 30,
        height: 40,
        width: 60,
        // width: 19,
        top: 0,
        left: 0,
        rotation: 0,
        sortorder: 0,
      };
      clone.decorators.push(newdecorator);
      setSelectedOutfit(clone);
      setTextToAddToOutfit('');
    };

    const handleOutfitSave = async () => {
      if (!selectedOutfit) return;
      if (!user) return;
      if (selectedOutfit.id === 0) {
        await createOutfit(user as UserAuth, selectedOutfit as Outfit)
          .then((res) => res.json())
          .then((result) => {
            if (outfits) {
              outfits.push(result);
              setSelectedOutfit(undefined);
              setShowCreateOutfitView(false);
              setItemsSelectedForOutfit(undefined);
              setSelectedEntityType('Outfits');
            }
          })
          .catch((err) => console.log(err));
      } else {
        await updateOutfit(user, selectedOutfit.id, selectedOutfit)
          .then(() => {
            const clone: Outfit[] = structuredClone(outfits);
            const indx = clone.findIndex((g) => g.id === selectedOutfit.id);
            clone[indx] = selectedOutfit;
            setOutfits(clone);
            setSelectedOutfit(undefined);
            setShowCreateOutfitView(false);
            setItemsSelectedForOutfit(undefined);
            setSelectedEntityType('Outfits');
          })
          .catch((er) => console.log(er));
      }
    };

    const handleEnlargeOutfitPart = (isIncrease: boolean) => {
      if (!selectedOutfit) return;
      if (!selectedOutfitPart) return;
      const changefactor = isIncrease ? 1.1 : 0.9;
      const clone: Outfit = structuredClone(selectedOutfit);

      // TODO: prevent h or w from exceeding bounds

      if (selectedOutfitPart.type.indexOf('wardrobeitem') > -1) {
        clone.parts.filter((i) => i.id === selectedOutfitPart.id)[0].height =
          clone.parts.filter((i) => i.id === selectedOutfitPart.id)[0].height * changefactor;

        // TODO: can I get this from the element itself?
        const currWidth = clone.parts.filter((i) => i.id === selectedOutfitPart.id)[0].width;
        if (currWidth) {
          clone.parts.filter((i) => i.id === selectedOutfitPart.id)[0].width = currWidth * changefactor;
        }
      }
      if (selectedOutfitPart.type.indexOf('decorator') > -1) {
        if (!clone?.decorators || clone.decorators.length === 0) return;
        clone.decorators.filter((i) => i.id === selectedOutfitPart.id)[0].size =
          clone.decorators.filter((i) => i.id === selectedOutfitPart.id)[0].size * changefactor;
      }
      setSelectedOutfit(clone);
    };

    const handleRotateOutfitPart = () => {
      if (!selectedOutfit) return;
      if (!selectedOutfitPart) return;
      const clone: Outfit = structuredClone(selectedOutfit);
      if (selectedOutfitPart.type.indexOf('wardrobeitem') > -1) {
        clone.parts.filter((pi: OutfitPart) => pi.id === selectedOutfitPart.id)[0].rotation += 45;
      } else {
        clone.decorators.filter((pi: OutfitDecorator) => pi.id === selectedOutfitPart.id)[0].rotation += 45;
      }
      setSelectedOutfit(clone);
    };

    const handleRemoveOutfitPart = () => {
      if (!selectedOutfit) return;
      if (!selectedOutfitPart) return;
      const clone: Outfit = structuredClone(selectedOutfit);

      if (selectedOutfitPart.type.indexOf('wardrobeitem') > -1) {
        clone.parts = clone.parts.filter((i) => i.id !== selectedOutfitPart.id);
      }
      if (selectedOutfitPart.type.indexOf('decorator') > -1) {
        if (!clone?.decorators || clone.decorators.length === 0) return;
        clone.decorators = clone.decorators.filter((i) => i.id !== selectedOutfitPart.id);
      }
      setSelectedOutfit(clone);
    };

    return (
      <Modal size="xl" isOpen={showCreateOutfitView} onClose={() => setShowCreateOutfitView(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="lg" width="auto">
          <ModalHeader>Create Outfit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" flex={1}>
              <Text fontSize="xs">Add text to outfit:</Text>
              <Flex flex={1} justifyContent="space-between" mb={4}>
                <Flex flex={3} alignItems="center">
                  <Input value={textToAddToOutfit} onChange={(e) => setTextToAddToOutfit(e.target.value)} />
                  <AddIcon ml={2} onClick={handleAddNewDecoratorToOutfit} />
                </Flex>
                <Flex flex={1} alignItems="center" justifyContent="flex-end" ml={2}>
                  <Icon as={ImEnlarge2} px={2} color="blue.400" h={8} w={8} onClick={() => handleEnlargeOutfitPart(true)} />
                  <Icon as={ImShrink2} px={2} color="blue.400" h={8} w={8} onClick={() => handleEnlargeOutfitPart(false)} />
                  <Icon as={FaArrowRotateRight} color="blue.400" h={4} w={4} onClick={() => handleRotateOutfitPart()} />
                  <Icon as={RiDeleteBin2Line} ml={4} px={1} color="blue.400" h={8} w={8} onClick={handleRemoveOutfitPart} />
                </Flex>
              </Flex>
              <OutfitPartsEditor outfit={selectedOutfit as Outfit} setOutfit={setSelectedOutfit} setSelectedOutfitPart={setSelectedOutfitPart} />
              <Flex flexDir="column" mt={2}>
                <Text>Outfit Name</Text>
                <Input value={selectedOutfit?.name} onChange={(e) => setSelectedOutfit({ ...selectedOutfit, name: e.target.value } as Outfit)} />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="darkoutline"
              mr={4}
              onClick={() => {
                setShowCreateOutfitView(false);
              }}
            >
              Edit Items
            </Button>
            <Button
              variant="darkoutline"
              onClick={() => {
                setShowCreateOutfitView(false);
                setSelectedOutfit(undefined);
                setItemsSelectedForOutfit(undefined);
                setSelectedEntityType('Outfits');
              }}
            >
              Cancel
            </Button>
            <Button ml={4} variant="solid" onClick={handleOutfitSave}>
              Save Outfit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const handleSelectOutfitForLookBook = (outfit: Outfit) => {
    let clone = structuredClone(outfitsSelectedForLookBook);
    if (!clone) clone = [];
    clone.push(outfit);
    setOutfitsSelectedForLookBook(clone);
  };

  const handleRemoveOutfitSelectedForLookBook = (index: number) => {
    const clone: Outfit[] = structuredClone(outfitsSelectedForLookBook);
    clone.splice(index, 1);
    setOutfitsSelectedForLookBook(clone);
  };

  const handleDeleteOutfit = async (id: number) => {
    console.log('deleting: ', id);
    if (!user) return;
    await deleteOutfit(user, id)
      .then((result) => {
        let clone: Outfit[] = structuredClone(outfits);
        clone = clone.filter((c) => c.id !== id);
        setOutfits(clone);
        onCloseDelete();
      })
      .catch((err) => console.log(err));
  };

  const renderOutfit = (item: Outfit, i: number) => {
    const handleShareOutfitItem = () => {
      if (!shareOutfitRef.current) return;
      toPng(shareOutfitRef.current[i], { cacheBust: true })
        .then((dataUrl) => {
          const msg: CreateMessageDTO = {
            messageBody: '',
            imageDataUrl: dataUrl,
            recipients: [],
          };
          setShareMessage(msg); // this will trigger the modal
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const handleDownloadOutfit = async () => {
      if (!shareOutfitRef.current) {
        console.log('no shareOutfitRef.current');
        return;
      }
      toPng(shareOutfitRef.current[i], { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'outfit.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err.target);
          console.log('ERROR: ', err);
        });
    };
    return (
      <Flex key={item.id} flexDir="column" borderRadius="lg" borderColor="grey.300" borderWidth={1} p={4} justifyContent="flex-start" mr={8} mb={8}>
        <Flex justifyContent="space-between" pb={2}>
          <Text bg={item.createdby ? 'olive.400' : 'yellow.400'} color={item.createdby ? 'white' : 'charcoal'} px={2} py={1} borderRadius="lg" fontSize="xs">
            {item.createdby ? 'Recommended' : 'Owned'}
          </Text>
          <Text as="b">{item.name}</Text>
          <Flex>
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <Text color="blue.400" px="2" fontWeight="bold" fontSize="lg">
                  ...
                </Text>
              </PopoverTrigger>
              <PopoverContent bg="white" borderWidth={1} borderColor="black" mt={2} maxWidth="14rem">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Flex flexDir="column" m={1}>
                    <Text
                      my={1}
                      onClick={() => {
                        setSelectedOutfit(item);
                        setShowCreateOutfitView(true);
                        const alreadySelectedItems: WardrobeItem[] = [];
                        item.parts.forEach((p) => {
                          const wi: WardrobeItem = {
                            id: p.wardrobeItemId,
                            url: p.imageUrl,
                            thumbnailurl: p.imageUrl,
                            isarchived: false,
                            isfavorite: false,
                            userid: clientuserid,
                            islabeled: false,
                            hiddenFromRoomies: false,
                            wardrobeid: 0,
                            isdeleted: false,
                          };
                          alreadySelectedItems.push(wi);
                        });
                        setItemsSelectedForOutfit(alreadySelectedItems);
                        setSelectedEntityType('Items');
                      }}
                    >
                      Edit
                    </Text>
                    <Text my={1} onClick={handleShareOutfitItem}>
                      Share
                    </Text>
                    <Text my={1} onClick={handleDownloadOutfit}>
                      Download
                    </Text>
                    {item.createdby === currentProfessional.userid && (
                      <>
                        <Text my={1} onClick={onOpenDelete}>
                          Delete
                        </Text>
                        <AlertDialog
                          size="md"
                          motionPreset="slideInBottom"
                          leastDestructiveRef={cancelRef}
                          onClose={onCloseDelete}
                          isOpen={isOpenDelete}
                          isCentered
                        >
                          <AlertDialogOverlay />
                          <AlertDialogContent>
                            <AlertDialogHeader>Confirm Delete</AlertDialogHeader>
                            <AlertDialogCloseButton />
                            <AlertDialogBody>Are you sure you want to delete this?</AlertDialogBody>
                            <AlertDialogFooter>
                              <Button ref={cancelRef} onClick={onCloseDelete}>
                                Cancel
                              </Button>
                              <Button colorScheme="red" ml={3} onClick={() => handleDeleteOutfit(item.id)}>
                                Delete
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </>
                    )}
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
        <Flex flexDir="column" ref={(el) => (shareOutfitRef.current[i] = el)}>
          <OutfitPartsDisplay outfit={item} height={OUTFIT_DISPLAY_HEIGHT} width={OUTFIT_DISPLAY_HEIGHT} resizeRatio={1} />
        </Flex>
        <Flex justifyContent="flex-end" p={1} onClick={() => handleSelectOutfitForLookBook(item)}>
          <AddIcon />
        </Flex>
      </Flex>
    );
  };

  const constructAndShowNewLookBookView = () => {
    clearFilePicker();
    console.log('selectedLookbook: ', selectedLookBook);
    if (selectedLookBook && selectedLookBook.id > 0) {
      // this is an edit case
      // setShowCreateLookBookView(true);
      // return;
      outfitsSelectedForLookBook?.forEach((outfit: Outfit) => {
        selectedLookBook.outfitIds.push(outfit.id);
        selectedLookBook.outfits.push(outfit);
      });
      setSelectedLookBook(selectedLookBook);
      setShowCreateLookBookView(true);
      setSelectedEntityType('LookBooks');
      return;
    }
    // This is a new LookBook case. Create shell LookBook from selected outfits
    const lookBook: LookBook = {
      id: 0,
      isdeleted: false,
      ispublished: false,
      createdby: currentProfessional.userid,
      userid: clientuserid,
      outfits: [],
      outfitIds: [],
    };
    outfitsSelectedForLookBook?.forEach((outfit: Outfit) => {
      lookBook.outfitIds.push(outfit.id);
      lookBook.outfits.push(outfit);
    });
    setSelectedLookBook(lookBook);
    setShowCreateLookBookView(true);
    setSelectedEntityType('LookBooks');
  };

  const renderOutfitsSelectedForLookBook = () => (
    <Flex zIndex={50} h={48} width="100%" flex={1} bg="rgba(0, 0, 0, 0.48)" p={4} position="fixed" bottom={0}>
      <Flex p={4} backdropBlur="none">
        {outfitsSelectedForLookBook &&
          outfitsSelectedForLookBook.map((item, i) => (
            <Box key={i.toString()} bg="white" rounded="lg" opacity={1} p={4} mr={4}>
              <SmallCloseIcon
                position="absolute"
                zIndex={3001}
                transform="translateY(-6px) translateX(-4px)"
                color="black"
                onClick={() => handleRemoveOutfitSelectedForLookBook(i)}
              />
              <OutfitPartsDisplay outfit={item} height={OUTFIT_DISPLAY_HEIGHT * 0.25} width={OUTFIT_DISPLAY_HEIGHT * 0.25} resizeRatio={0.25} />
            </Box>
          ))}
        <Flex flexDir="column" justifyContent="space-around" ml={8}>
          <Button variant="solid" onClick={constructAndShowNewLookBookView}>
            {selectedLookBook && selectedLookBook.id > 0 ? 'Add to' : 'Create'} LookBook
          </Button>
          <Button variant="darkoutline" onClick={() => setOutfitsSelectedForLookBook(undefined)}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );

  const renderCreateOrEditLookBookModal = () => {
    const handleLookBookSave = async () => {
      if (!user) return;
      if (!selectedLookBook) return;
      if (filesContent && filesContent[0] && filesContent[0].content) selectedLookBook.background = filesContent[0].content;
      if (selectedLookBook.id < 1) {
        await createLookBook(user as UserAuth, selectedLookBook as LookBook)
          .then((res) => res.json())
          .then((result) => {
            if (lookBooks) {
              lookBooks.push(result);
              setSelectedLookBook(undefined);
              setShowCreateLookBookView(false);
              setOutfitsSelectedForLookBook(undefined);
              clearFilePicker();
              setSelectedEntityType('LookBooks');
            }
          })
          .catch((err) => console.log(err));
      } else {
        // update existing
        await updateLookBook(user, selectedLookBook.id, selectedLookBook)
          .then((res) => res.json())
          .then((result) => {
            const clone: LookBook[] = structuredClone(lookBooks);
            const indx = clone.findIndex((g) => g.id === selectedLookBook.id);
            clone[indx] = result;
            setLookBooks(clone);
            setSelectedLookBook(undefined);
            setShowCreateLookBookView(false);
            setOutfitsSelectedForLookBook(undefined);
            clearFilePicker();
          })
          .catch((err) => console.log(err));
      }
    };

    return (
      <Modal size="lg" isCentered isOpen={showCreateLookBookView} onClose={() => setShowCreateLookBookView(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>{selectedLookBook?.id && selectedLookBook.id > 0 ? 'Edit' : 'Create'} LookBook</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column">
              <Flex flexDir="column">
                <Text>LookBook Name</Text>
                <Input value={selectedLookBook?.name} onChange={(e) => setSelectedLookBook({ ...selectedLookBook, name: e.target.value } as LookBook)} />
              </Flex>
              <Flex flexDir="column" my={2}>
                <Text>Description</Text>
                <Textarea
                  value={selectedLookBook?.description}
                  onChange={(e) => setSelectedLookBook({ ...selectedLookBook, description: e.target.value } as LookBook)}
                />
              </Flex>
              <Flex flexDir="column" my={4}>
                <Button
                  mb={2}
                  variant="solid"
                  onClick={() => {
                    clearFilePicker();
                    openFileSelector();
                  }}
                >
                  Choose Background Image
                </Button>
                {loading && <Spinner />}
                {errors && errors.length > 0 && <Text>Error: file size too large.</Text>}
                {selectedLookBook && selectedLookBook.id > 0 && (
                  <ChakraImage src={filesContent && filesContent[0] ? filesContent[0].content : selectedLookBook?.background} maxH={64} fit="contain" />
                )}
                {!selectedLookBook ||
                  (selectedLookBook.id === 0 && (
                    <ChakraImage src={filesContent && filesContent[0] ? filesContent[0].content : undefined} maxHeight={64} fit="contain" />
                  ))}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter my={4}>
            <Flex flex={1} justifyContent="space-between">
              <Button
                variant="darkoutline"
                onClick={() => {
                  setShowCreateLookBookView(false);
                  setSelectedLookBook(undefined);
                }}
              >
                Cancel
              </Button>
              <Button variant="solid" onClick={handleLookBookSave}>
                Save LookBook
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const renderViewLookBookDetailsModal = () => (
    <Modal
      blockScrollOnMount={false}
      size="lg"
      isCentered
      isOpen={showViewLookBookDetailsModal}
      onClose={() => {
        setSelectedLookBook(undefined);
        setShowViewLookBookDetailsModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>LookBook Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column">
            <Flex flexDir="column">
              <Text color="orange.400">LookBook Name:</Text>
              <Text>{selectedLookBook?.name}</Text>
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text color="orange.400">Description:</Text>
              <Text>{selectedLookBook?.description}</Text>
            </Flex>
            <Flex flexDir="column" my={4}>
              {selectedLookBook && selectedLookBook.id > 0 && (
                <ChakraImage src={filesContent && filesContent[0] ? filesContent[0].content : selectedLookBook?.background} maxH={64} fit="contain" />
              )}
            </Flex>
            {selectedLookBook && selectedLookBook.outfits && (
              <Text mt={4} color="orange.400">
                Outfits:
              </Text>
            )}
            <Flex flexDir="row" overflowX="scroll">
              {selectedLookBook?.outfits?.map((o) => (
                <Box key={o.id} mr={2} borderWidth={1} borderRadius="md" py={1}>
                  <OutfitPartsDisplay outfit={o} height={OUTFIT_DISPLAY_HEIGHT * 0.5} width={OUTFIT_DISPLAY_HEIGHT * 0.5} resizeRatio={0.5} />
                </Box>
              ))}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  const handleDeleteLookbook = async (id: number) => {
    console.log('deleting: ', id);
    if (!user) return;
    await deleteLookBook(user, id)
      .then((result) => {
        let clone: LookBook[] = structuredClone(lookBooks);
        clone = clone.filter((c) => c.id !== id);
        setLookBooks(clone);
        onCloseDelete();
      })
      .catch((err) => console.log(err));
  };

  const renderLookBook = (item: LookBook, i: number) => {
    const handleShareLookBookItem = () => {
      if (!shareLookBookRef.current) return;
      toPng(shareLookBookRef.current[i], { cacheBust: true })
        .then((dataUrl) => {
          const msg: CreateMessageDTO = {
            messageBody: '',
            imageDataUrl: dataUrl,
            recipients: [],
          };
          setShareMessage(msg); // this will trigger the modal
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return (
      <Flex key={item.id.toString()} flexDir="column" mr={8} mb={8}>
        <Flex borderWidth={1} borderRadius="lg" p={2}>
          <Flex ref={(el) => (shareLookBookRef.current[i] = el)}>
            {item.background && <ChakraImage src={item.background} height={OUTFIT_DISPLAY_HEIGHT * 0.5} width={OUTFIT_DISPLAY_HEIGHT * 0.5} fit="contain" />}
            {!item.background && item.outfits && item.outfits.length > 0 && (
              <OutfitPartsDisplay outfit={item.outfits[0]} height={OUTFIT_DISPLAY_HEIGHT * 0.5} width={OUTFIT_DISPLAY_HEIGHT * 0.5} resizeRatio={0.5} />
            )}
          </Flex>
          <Popover placement="top-end">
            <PopoverTrigger>
              <Flex flexDir="column">
                <Text color="blue.400" px="2" fontWeight="bold" fontSize="xl">
                  ...
                </Text>
                <Flex flex={1}>
                  <Box />
                </Flex>
              </Flex>
            </PopoverTrigger>
            <PopoverContent bg="white" borderWidth={1} borderColor="black" mt={2} maxWidth="14rem">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Flex flexDir="column" m={1}>
                  <Text
                    my={1}
                    onClick={() => {
                      setSelectedLookBook(item);
                      setShowViewLookBookDetailsModal(true);
                    }}
                  >
                    Details
                  </Text>
                  <Text my={1} onClick={handleShareLookBookItem}>
                    Share
                  </Text>
                  <Text
                    my={1}
                    onClick={() => {
                      setSelectedLookBook(item); // ?
                      setOutfitsSelectedForLookBook(item.outfits);
                      console.log('item: ', item);
                      setSelectedEntityType('Outfits');
                    }}
                  >
                    Edit Outfits
                  </Text>
                  {item.createdby === currentProfessional.userid && (
                    <>
                      <Text my={1} onClick={onOpenDelete}>
                        Delete
                      </Text>
                      <AlertDialog
                        size="md"
                        motionPreset="slideInBottom"
                        leastDestructiveRef={cancelRef}
                        onClose={onCloseDelete}
                        isOpen={isOpenDelete}
                        isCentered
                      >
                        <AlertDialogOverlay />
                        <AlertDialogContent>
                          <AlertDialogHeader>Confirm Delete</AlertDialogHeader>
                          <AlertDialogCloseButton />
                          <AlertDialogBody>Are you sure you want to delete this?</AlertDialogBody>
                          <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseDelete}>
                              Cancel
                            </Button>
                            <Button colorScheme="red" ml={3} onClick={() => handleDeleteLookbook(item.id)}>
                              Delete
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </>
                  )}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
        <Flex flexDir="column" my={1}>
          <Text fontSize="lg" fontWeight="bold">
            {item.name}
          </Text>
          <Text>{item.description}</Text>
        </Flex>
      </Flex>
    );
  };

  const handleSort = (sortval: string) => {
    // this just sets sort vals in state variables that are added into the typed queries in the useEffects
    if (sortval === 'dateasc') {
      setSortExpression('createddate');
      setSortDirection('asc');
    }
    if (sortval === 'datedesc') {
      setSortExpression('createddate');
      setSortDirection('desc');
    }
  };

  const renderFilter = () => {
    const filterBody = () => {
      if (selectedEntityType === 'Items') {
        return (
          <>
            <Flex flexDir="column" my={4}>
              <Text>Category</Text>
              <PrimaryCategorySelector
                value={wardrobeItemsQueryWIP.primary ? wardrobeItemsQueryWIP.primary : WardrobeItemCategory.All}
                handleSelection={(e) => setWardrobeItemsQueryWIP({ ...wardrobeItemsQueryWIP, primary: e.target.value })}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Subcategory</Text>
              <Select
                variant="flushed"
                value={wardrobeItemsQueryWIP.subcategory || undefined}
                onChange={(e) => setWardrobeItemsQueryWIP({ ...wardrobeItemsQueryWIP, subcategory: e.target.value })}
              >
                {/* {WardrobeItemSubCategories.map((sc, i) => (
                  <option key={i.toString()} value={sc}>
                    {sc}
                  </option>
                ))} */}
                {subcategoriesForUser.map((sc, i) => (
                  <option key={i.toString()} value={sc}>
                    {sc}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Brand</Text>
              <Input value={wardrobeItemsQueryWIP.brand} onChange={(e) => setWardrobeItemsQueryWIP({ ...wardrobeItemsQueryWIP, brand: e.target.value })} />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Color</Text>
              <Input value={wardrobeItemsQueryWIP.color} onChange={(e) => setWardrobeItemsQueryWIP({ ...wardrobeItemsQueryWIP, color: e.target.value })} />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Tags</Text>
              <MultiSelect
                create
                multiple={false}
                minW="80%"
                options={client?.user && client.user.tags ? client.user.tags.map((tag) => ({ label: tag, value: tag })) : []}
                value={wardrobeItemsQueryWIP.tags || undefined}
                onChange={(e) => {
                  const clone: WardrobeItemQuery = structuredClone(wardrobeItemsQueryWIP);
                  clone.tags = e as string[];
                  setWardrobeItemsQueryWIP(clone);
                }}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Season</Text>
              <Select
                variant="flushed"
                value={wardrobeItemsQueryWIP.weather ? wardrobeItemsQueryWIP.weather : 'all'}
                onChange={(e) => setWardrobeItemsQueryWIP({ ...wardrobeItemsQueryWIP, weather: e.target.value })}
              >
                <option value="all">All Seasons</option>
                <option value="fall">Fall</option>
                <option value="winter">Winter</option>
                <option value="spring">Spring</option>
                <option value="summer">Summer</option>
              </Select>
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Ownership</Text>
              <Select
                variant="flushed"
                value={wardrobeItemsQueryWIP.ownership ? wardrobeItemsQueryWIP.ownership : 'all'}
                onChange={(e) => setWardrobeItemsQueryWIP({ ...wardrobeItemsQueryWIP, ownership: e.target.value })}
              >
                <option value="all">All</option>
                <option value="owned">Owned by Client</option>
                <option value="unowned">Recommended to client</option>
              </Select>
            </Flex>
            <Flex justifyContent="space-between">
              <Text
                color="charcoal"
                p={1}
                onClick={() => {
                  setWardrobeItemsQueryWIP({ ...defaultWardrobeItemQuery, userid: clientuserid });
                  setShowFilters(false);
                }}
              >
                Cancel
              </Text>
              <Text
                color="orange.400"
                p={1}
                onClick={() => {
                  setWardrobeItemsQuery(wardrobeItemsQueryWIP);
                  setShowFilters(false);
                }}
              >
                Apply
              </Text>
            </Flex>
          </>
        );
      }
      if (selectedEntityType === 'Outfits') {
        return (
          <>
            <Flex flexDir="column" my={2}>
              <Text>Tags</Text>
              <MultiSelect
                create
                multiple={false}
                minW="80%"
                options={client?.user && client.user.tags ? client.user.tags.map((tag) => ({ label: tag, value: tag })) : []}
                value={outfitQueryWIP.tags || undefined}
                onChange={(e) => {
                  const clone: OutfitQuery = structuredClone(outfitQueryWIP);
                  clone.tags = e as string[];
                  setOutfitQueryWIP(clone);
                }}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Ownership</Text>
              <Select
                variant="flushed"
                value={outfitQueryWIP.ownership ? outfitQueryWIP.ownership : 'all'}
                onChange={(e) => setOutfitQueryWIP({ ...outfitQueryWIP, ownership: e.target.value })}
              >
                <option value="all">All</option>
                <option value="owned">Owned by Client</option>
                <option value="unowned">Recommended to client</option>
              </Select>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
              <Text
                color="blue.400"
                p={1}
                onClick={() => {
                  setOutfitQueryWIP({ ...defaultOutfitQuery, userid: clientuserid });
                  setShowFilters(false);
                }}
              >
                Cancel
              </Text>
              <Text
                color="blue.400"
                p={1}
                onClick={() => {
                  setOutfitQuery(outfitQueryWIP);
                  setShowFilters(false);
                }}
              >
                Apply
              </Text>
            </Flex>
          </>
        );
      }
      if (selectedEntityType === 'LookBooks') {
        return (
          <>
            <Flex flexDir="column" my={2}>
              <Text>Tags</Text>
              <MultiSelect
                create
                multiple={false}
                minW="80%"
                options={client?.user && client.user.tags ? client.user.tags.map((tag) => ({ label: tag, value: tag })) : []}
                value={lookBookQueryWIP.tags || undefined}
                onChange={(e) => {
                  const clone: LookBookQuery = structuredClone(lookBookQueryWIP);
                  clone.tags = e as string[];
                  setLookBookQueryWIP(clone);
                }}
              />
            </Flex>
            <Flex flexDir="column" my={2}>
              <Text>Ownership</Text>
              <Select
                variant="flushed"
                value={lookBookQueryWIP.ownership ? lookBookQueryWIP.ownership : 'all'}
                onChange={(e) => setLookBookQueryWIP({ ...lookBookQueryWIP, ownership: e.target.value })}
              >
                <option value="all">All</option>
                <option value="owned">Owned by Client</option>
                <option value="unowned">Recommended to client</option>
              </Select>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
              <Text
                color="blue.400"
                p={1}
                onClick={() => {
                  setLookBookQueryWIP({ ...defaultLookBookQuery, userid: clientuserid });
                  setShowFilters(false);
                }}
              >
                Cancel
              </Text>
              <Text
                color="blue.400"
                p={1}
                onClick={() => {
                  setLookBookQuery(lookBookQueryWIP);
                  setShowFilters(false);
                }}
              >
                Apply
              </Text>
            </Flex>
          </>
        );
      }
      return <Box />;
    };

    return (
      <>
        <Button variant="solid" rightIcon={<ChevronDownIcon />} onClick={() => setShowFilters(true)}>
          Filter
        </Button>
        <Popover placement="bottom-end" isOpen={showFilters} onClose={() => setShowFilters(false)}>
          <PopoverTrigger>
            {/* <Button variant="solid" h={0} w={0} rightIcon={<ChevronDownIcon />}>
              Filter
            </Button> */}
            <Box />
          </PopoverTrigger>
          <PopoverContent bg="white" p={2}>
            <PopoverArrow />
            <PopoverHeader fontSize="lg" fontWeight="bold">
              Filter
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody bg="blue.400" minW={96}>
              <Flex flexDir="column" p={4} bg="white" minW={80}>
                {filterBody()}
              </Flex>
            </PopoverBody>
            {/* <PopoverFooter>This is the footer</PopoverFooter> */}
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const renderHeaderControls = () => (
    <Flex mb={4} justifyContent="center" alignItems="center" flex={1}>
      <Flex flex={1}>{renderFilter()}</Flex>
      <Select
        variant="outline"
        placeholder="Sort..."
        flex={1}
        mx={1}
        bg="white"
        color="charcoal"
        borderColor="charcoal"
        fontWeight="bold"
        onChange={(e) => handleSort(e.target.value)}
      >
        <option value="datedesc">Newest</option>
        <option value="dateasc">Oldest</option>
      </Select>
      <Flex flex={2}>
        <InputGroup bg="white" mx={4} borderRadius="3xl">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="blue.400" />
          </InputLeftElement>
          <Input placeholder="Search..." borderRadius="3xl" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        </InputGroup>
      </Flex>
      <Flex flex={1}>{renderEntityTabButtons()}</Flex>
      <Flex flex={3} justifyContent="flex-end" alignItems="center">
        <Text color="orange.400">Add New</Text>
        <Menu>
          <MenuButton as={IconButton} aria-label="Options" icon={<AddIcon />} bg="white" p={0} />
          <MenuList>
            <MenuItem onClick={() => setShowNewWardrobeItemModal(true)}>Item</MenuItem>
            <MenuItem
              onClick={() => {
                handleSelectItemForOutfit(wardrobeItems[0]);
                setSelectedEntityType('Items');
              }}
            >
              Outfit
            </MenuItem>
            <MenuItem onClick={constructAndShowNewLookBookView}>LookBook</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );

  if (loadingData) return <Spinner />;

  return (
    <Flex flexDir="column" my={4} flex={1} width="100%">
      {renderHeaderControls()}
      {selectedEntityType && selectedEntityType === 'Items' && (
        <Flex mx={4} flexDir="column" pb={48}>
          <Grid flex={1} templateColumns="repeat(auto-fill, minmax(22rem, 1fr))" gap={6}>
            {wardrobeItems && wardrobeItems.map((item, i) => renderWardrobeItem(item, i))}
          </Grid>
          <Pagination
            currentPage={wardrobeItemsQuery.po}
            totalCount={totalWardrobeItems}
            pageSize={PAGE_SIZE}
            onPageChange={(page: any) => setWardrobeItemsQuery({ ...wardrobeItemsQuery, po: page })}
          />
          {wardrobeItems && totalWardrobeItems === 0 && <Text>No results to display.</Text>}
        </Flex>
      )}
      {selectedEntityType && selectedEntityType === 'Outfits' && (
        <Flex mx={4}>
          <Flex flex={1} flexWrap="wrap" pb={48}>
            {outfits && outfits.map((item, i) => renderOutfit(item, i))}
          </Flex>
          <Pagination
            currentPage={outfitQuery.po}
            totalCount={totalOutfits}
            pageSize={PAGE_SIZE}
            onPageChange={(page: any) => setOutfitQuery({ ...outfitQuery, po: page })}
          />
          {outfits && totalOutfits === 0 && <Text>No results to display.</Text>}
        </Flex>
      )}
      {selectedEntityType && selectedEntityType === 'LookBooks' && (
        <Flex mx={4}>
          <Flex flex={1} flexWrap="wrap" pb={48}>
            {lookBooks && lookBooks.map((item, i) => renderLookBook(item, i))}
            {!lookBooks || (lookBooks.length === 0 && <Text onClick={constructAndShowNewLookBookView}>Add your first LookBook!</Text>)}
          </Flex>
          <Pagination
            currentPage={lookBookQuery.po}
            totalCount={totalLookBooks}
            pageSize={PAGE_SIZE}
            onPageChange={(page: any) => setLookBookQuery({ ...lookBookQuery, po: page })}
          />
        </Flex>
      )}
      {renderWardrobeItemDetailsDrawer()}
      {renderAddWardrobeItemModal()}
      {itemsSelectedForOutfit && itemsSelectedForOutfit.length > 0 && renderItemsSelectedForOutfit()}
      {outfitsSelectedForLookBook && outfitsSelectedForLookBook.length > 0 && renderOutfitsSelectedForLookBook()}
      {renderCreateOutfitModal()}
      {renderShareMessageModal()}
      {renderCreateOrEditLookBookModal()}
      {renderViewLookBookDetailsModal()}
    </Flex>
  );
}

export default ClientWardrobeScreen;
