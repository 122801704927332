/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import {
  Button,
  Image,
  Flex,
  Text,
  Input,
  useToast,
  Switch,
  Spinner,
  Box,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Alert,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiSelect } from 'chakra-multiselect';
import { useFilePicker } from 'use-file-picker';
import { ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';
import { auth } from '../../config/firebase';
import { CurrentProfessionalContext } from '../../contexts/CurrentProfessionalProvider';
import { CollageItem, FeaturedContentTopicItem } from '../../models/FeaturedContentModels';
import {
  deleteFeaturedContentTopicItem,
  fetchFeaturedContentForUser,
  fetchFeaturedContentTopicItem,
  fetchFeaturedContentTopicTags,
  upsertFeaturedContentTopicItem,
} from '../../services/featuredContentService';
import LoadingScreen from '../../components/LoadingScreen';
import AddIcon from '../../components/AddIcon';
import CollageComponent from './CollageComponent';
import loggerService from '../../services/LoggerService';

export interface CollageItemImageHolder {
  collageItemId: number;
  image: File;
}

function FeaturedContentTopicItemScreen() {
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const [showSpinner, setShowSpinner] = useState(false);
  const { state } = useLocation();
  const { id } = state;
  const [featuredContentTopicItem, setFeaturedContentTopicItem] = useState<FeaturedContentTopicItem>();
  const [featuredContentTopicItemCollageImageFile, setFeaturedContentTopicItemCollageImageFile] = useState<File | undefined>();
  // const [collageImageFiles, setCollageImageFiles] = useState<File[] | undefined>();
  const [collageImageFiles, setCollageImageFiles] = useState<CollageItemImageHolder[] | undefined>();
  const [featuredContentTopicTags, setFeaturedContentTopicTags] = useState<string[]>();
  const [validationErrors, setValidationErrors] = useState<string[] | undefined>();

  const addBlankCollageComponentItem = () => {
    const item: FeaturedContentTopicItem = structuredClone(featuredContentTopicItem);
    if (!item.collageItems) item.collageItems = [];
    const defaultCollageItem: CollageItem = {
      id: Math.max(...item.collageItems.map((p) => p.id)) + 1,
      imageurl: '',
      link: '',
      brandname: '',
    };
    item.collageItems?.push(defaultCollageItem);
    setFeaturedContentTopicItem(item);
  };

  useEffect(() => {
    if (!user) return;
    const fetch = async () => {
      await fetchFeaturedContentTopicItem(user, id)
        .then((res) => res.json())
        .then((result: FeaturedContentTopicItem) => {
          if (!result.collageItems) {
            result.collageItems = [];
            const defaultCollageItem: CollageItem = {
              id: 1,
              imageurl: '',
              link: '',
              brandname: '',
            };
            result.collageItems?.push(defaultCollageItem);
          }
          setFeaturedContentTopicItem(result);
        })
        .finally(() => setShowSpinner(false));
    };

    setShowSpinner(true);
    if (id) fetch().catch(console.error);
    else {
      const item: FeaturedContentTopicItem = {
        id: -1,
        contenttype: 'collage',
        createddate: new Date(),
        createdbyuserid: currentProfessional.userid,
        createdbyname: currentProfessional.username,
        createdbyavatarurl: currentProfessional.useravatarurl,
        status: 'Draft',
        title: '',
        collageItems: [],
      };
      const defaultCollageItem: CollageItem = {
        id: 1,
        imageurl: '',
        link: '',
        brandname: '',
      };
      item.collageItems?.push(defaultCollageItem);
      setFeaturedContentTopicItem(item);
      setShowSpinner(false);
    }
  }, [user, id]);

  useEffect(() => {
    if (!user) return;
    if (featuredContentTopicTags && featuredContentTopicTags.length > 0) return;
    const fetch = async () => {
      await fetchFeaturedContentTopicTags(user)
        .then((res) => res.json())
        .then((result: string[]) => {
          if (!result) result = [];
          setFeaturedContentTopicTags(result);
        });
    };
    fetch().catch(console.error);
  }, [user]);

  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 200,
    imageSizeRestrictions: {
      maxHeight: 10000, // in pixels
      maxWidth: 10000,
      minHeight: 20,
      minWidth: 20,
    },
    onFilesSuccessfulySelected: (data) => {
      setFeaturedContentTopicItem({ ...featuredContentTopicItem, collageimageurl: data.filesContent[0].content } as FeaturedContentTopicItem);
      setFeaturedContentTopicItemCollageImageFile(data.plainFiles[0]);
      clearFilePicker();
    },
  });

  const updateCollageItemField = (collageitemid: number, field: string, value: string) => {
    const clone: FeaturedContentTopicItem = structuredClone(featuredContentTopicItem);
    if (!clone.collageItems) return;
    const cloneCollageItem: CollageItem = clone.collageItems.filter((i) => i.id === collageitemid)[0];
    if (!cloneCollageItem) return;
    (cloneCollageItem as any)[field] = value;
    setFeaturedContentTopicItem(clone);
  };

  const addCollageItemImage = (collageitemid: number, imgfile: File) => {
    let clone: CollageItemImageHolder[] | undefined = structuredClone(collageImageFiles);
    if (!clone) clone = [];
    clone.push({
      collageItemId: collageitemid,
      image: imgfile,
    });
    console.log('clone: ', clone);
    setCollageImageFiles(clone);
  };

  const removeCollageItem = (collageItemId: number) => {
    const clone: FeaturedContentTopicItem = structuredClone(featuredContentTopicItem);
    if (!clone.collageItems) return;
    clone.collageItems = clone.collageItems.filter((i) => i.id !== collageItemId);
    setFeaturedContentTopicItem(clone);

    // also remove associated new image, if any:
    let imgsclone: CollageItemImageHolder[] | undefined = structuredClone(collageImageFiles);
    if (imgsclone) {
      imgsclone = imgsclone.filter((i) => i.collageItemId !== collageItemId);
      setCollageImageFiles(imgsclone);
    }
  };

  function isTopicItemValid(): boolean {
    if (!featuredContentTopicItem) return false;
    let validationErrorsClone: string[] = []; // structuredClone(validationErrors);
    if (!validationErrorsClone) validationErrorsClone = [];

    if (!featuredContentTopicItem.hashtags || featuredContentTopicItem.hashtags.length === 0) {
      validationErrorsClone.push('Must include at least one Tag');
    }

    if (featuredContentTopicItem.contenttype === 'collage') {
      if (!featuredContentTopicItem.collageimageurl) validationErrorsClone.push('Missing Outfit Picture');

      if (!featuredContentTopicItem.collageItems || featuredContentTopicItem.collageItems.length === 0)
        validationErrorsClone.push('Must include at least one Item');

      featuredContentTopicItem.collageItems?.forEach((item) => {
        if (!item.link || !item.brandname || !item.imageurl) validationErrorsClone.push('Each Item must have a Link, Brand Name, and Image');
      });
    }

    setValidationErrors(validationErrorsClone);
    if (validationErrorsClone.length > 0) return false;
    return true;
  }

  useEffect(() => {
    if (!user) return;
    if (!featuredContentTopicItem) return;
    isTopicItemValid();
  }, [featuredContentTopicItem]);

  const handleSaveTopicItem = async (isSubmission = false) => {
    if (!featuredContentTopicItem) return;
    setShowSpinner(true);
    const clone: FeaturedContentTopicItem = structuredClone(featuredContentTopicItem);
    clone.status = isSubmission ? 'Submitted' : 'Draft';

    if (isSubmission && !isTopicItemValid()) {
      toast({
        title: 'Validation Issues',
        description: validationErrors?.join(', '),
        status: 'warning',
        duration: 1500,
        isClosable: true,
      });
      return;
    }

    // Files[]
    let allFiles: File[] = [];
    console.log('collageImageFile count: ', collageImageFiles?.length);
    if (featuredContentTopicItemCollageImageFile) allFiles.push(featuredContentTopicItemCollageImageFile);
    if (collageImageFiles) allFiles = allFiles.concat(collageImageFiles.map((c) => c.image));

    await upsertFeaturedContentTopicItem(user as UserAuth, clone, allFiles)
      .then((res) => {
        if (!res.ok) {
          toast({
            title: 'Error',
            description: 'Something went wrong.',
            status: 'error',
            duration: 1500,
            isClosable: true,
          });
          throw new Error('Error', res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((result: FeaturedContentTopicItem) => setFeaturedContentTopicItem(result))
      .then(() => clearFilePicker())
      .then(() =>
        toast({
          title: 'Saved',
          description: 'Content has been saved.',
          status: 'success',
          duration: 1500,
          isClosable: true,
        })
      )
      .catch((err) => console.log(err))
      .finally(() => {
        setShowSpinner(false);
        onClose();
      });
  };

  const handleDeleteTopicItem = async () => {
    if (!featuredContentTopicItem) return;
    await deleteFeaturedContentTopicItem(user as UserAuth, featuredContentTopicItem.id).then(() => navigate('/featuredcontent'));
  };

  if (showSpinner) return <LoadingScreen />;

  return (
    <Flex flexDir="column" flex={1} p={4}>
      <Flex flex={1} width="100%">
        <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="blue.400" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/featuredcontent">All Content</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="">Create Content</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex flex={1} flexDir="column" px={12} py={8}>
        <Flex flexDirection="row" justifyContent="space-between" alignItems="center" mb={6}>
          <HStack>
            <Text variant="header" color="charcoal" fontWeight="bold" mr={6}>
              {id ? 'View Content' : 'Create Content'}
            </Text>
            <Text px={8} fontWeight="bold" bg={featuredContentTopicItem?.status === 'Draft' ? 'grey.50' : 'blue.400'} borderRadius="lg">
              {featuredContentTopicItem?.status}
            </Text>
          </HStack>
          <Text color="grey">* Required</Text>
        </Flex>
        <Flex flexDirection="row" flex={1} mt={6}>
          <Flex flexDir="column" flex={1} borderRadius="md" borderWidth={0.5} p={4} mr={4} minH={36}>
            <Text fontWeight="bold" fontSize="lg">
              Outfit*
            </Text>
            {!featuredContentTopicItem?.collageimageurl && <Image src="/icons/camera_add.png" objectFit="contain" w={12} my={4} onClick={openFileSelector} />}
            {loading && <Spinner />}
            {errors && errors.length > 0 && <Text>{JSON.stringify(errors)}</Text>}
            {featuredContentTopicItem?.collageimageurl && (
              <>
                <Image src={featuredContentTopicItem.collageimageurl} maxHeight={64} fit="contain" />
                {featuredContentTopicItem.status === 'Draft' && (
                  <Flex justifyContent="flex-end">
                    <DeleteIcon
                      display="flex"
                      alignSelf="center"
                      justifySelf="center"
                      m={2}
                      onClick={() => setFeaturedContentTopicItem({ ...featuredContentTopicItem, collageimageurl: undefined })}
                    />
                  </Flex>
                )}
              </>
            )}
          </Flex>
          <Flex flexDir="column" flex={1}>
            <HStack mb={2}>
              <Text fontWeight="bold" fontSize="lg">
                Tags*
              </Text>
              <Text fontSize="sm" color="grey">
                min 1
              </Text>
            </HStack>
            {featuredContentTopicItem?.status === 'Draft' && (
              <MultiSelect
                create
                multiple
                options={featuredContentTopicTags ? featuredContentTopicTags.map((tag) => ({ label: tag, value: tag })) : []}
                value={featuredContentTopicItem?.hashtags || undefined}
                onChange={(e) => {
                  if (featuredContentTopicItem?.status !== 'Draft') return;
                  const clone: FeaturedContentTopicItem = structuredClone(featuredContentTopicItem);
                  clone.hashtags = e as string[];
                  setFeaturedContentTopicItem(clone);
                }}
              />
            )}
            {featuredContentTopicItem && featuredContentTopicItem?.status !== 'Draft' && featuredContentTopicItem.hashtags && (
              <Box borderWidth={0.5} my={1}>
                <Text ml={4} fontWeight="bold">
                  {featuredContentTopicItem.hashtags.join(', ')}
                </Text>
              </Box>
            )}
            <Text color="grey.600" mt={1} size="xs">
              Select all Tags related to featured topics that this content applies to; or, enter your own tags
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column" flex={1} mt={6}>
          <HStack>
            <Text fontWeight="bold" fontSize="lg">
              Items*
            </Text>
            <Text fontSize="sm" color="grey">
              min 1
            </Text>
            {/* <AddIcon onClick={addBlankCollageComponentItem} /> */}
          </HStack>
          {featuredContentTopicItem?.collageItems &&
            featuredContentTopicItem.collageItems.map((item) => (
              <CollageComponent
                key={item.id.toString()}
                item={item}
                isDisabled={featuredContentTopicItem.status !== 'Draft'}
                updateHandler={updateCollageItemField}
                addImageHandler={addCollageItemImage}
                removeHandler={removeCollageItem}
              />
            ))}
          <Button mt={2} variant="solid" maxW={32} onClick={addBlankCollageComponentItem}>
            + Add Item
          </Button>
        </Flex>
        {featuredContentTopicItem?.status === 'Draft' && (
          <>
            <Flex flexDir="row" justifyContent="space-between" alignItems="center" mt={10}>
              <HStack>
                <Button variant="darkoutline" onClick={() => navigate('/featuredcontent')}>
                  Cancel
                </Button>
                {featuredContentTopicItem.status === 'Draft' && (
                  <>
                    <Button onClick={handleDeleteTopicItem}>Delete</Button>
                    <AlertDialog
                      size="md"
                      motionPreset="slideInBottom"
                      leastDestructiveRef={cancelRef}
                      onClose={onCloseDelete}
                      isOpen={isOpenDelete}
                      isCentered
                    >
                      <AlertDialogOverlay />
                      <AlertDialogContent>
                        <AlertDialogHeader>Confirm Delete</AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>Are you sure you want to delete this?</AlertDialogBody>
                        <AlertDialogFooter>
                          <Button ref={cancelRef} onClick={onCloseDelete}>
                            Cancel
                          </Button>
                          <Button colorScheme="red" ml={3} onClick={handleDeleteTopicItem}>
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </>
                )}
              </HStack>
              <HStack>
                <Button variant="solid" onClick={() => handleSaveTopicItem(false)}>
                  Save as Draft
                </Button>
                <Button variant="solid" isDisabled={validationErrors && validationErrors.length > 0} onClick={onOpen}>
                  Submit Content
                </Button>
              </HStack>
            </Flex>
            <AlertDialog size="md" motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogHeader>Submit Changes</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  Are you sure you want to submit this content to be featured? You will not be able to make changes to your outfit, items, or tags. We will
                  review your content and update the status if selected.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Go Back
                  </Button>
                  <Button colorScheme="red" ml={3} onClick={() => handleSaveTopicItem(true)}>
                    Submit Content
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </>
        )}
        {featuredContentTopicItem?.status === 'Submitted' && (
          <Flex flexDir="row" justifyContent="flex-end" mt={8}>
            <Button variant="solid" onClick={() => handleSaveTopicItem(false)}>
              Cancel Submission
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default FeaturedContentTopicItemScreen;
