/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HStack, Flex, Text, Input, useToast, Switch } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { auth } from '../config/firebase';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import { ProfessionalUser } from '../models/ProfessionalUser';
import { updateProfessionalDetails } from '../services/ProfessionalsService';

function AvailabilityScreen() {
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const [user, loading, error] = useAuthState(auth);
  const [responseTime, setResponseTime] = useState(currentProfessional?.schedulingpreferences?.responseTimeInDays);
  const [genAvailability, setGenAvailability] = useState(currentProfessional?.schedulingpreferences?.generalAvailability);
  const [acceptingNews, setAcceptingNews] = useState(currentProfessional?.schedulingpreferences?.acceptingNewClients);
  const toast = useToast();

  // useEffect(() => {
  //   if (!user) return;
  //   const fetchSched = async () => {
  //     await fetchUsersSchedule(user, user.uid)
  //       .then((res) => res.json())
  //       .then((result: ScheduleItem[]) => {
  //         console.log(result);
  //         setScheduleItems(result);
  //         const mapped: Event[] = result.map((si) => ({
  //           start: si.startDateTime,
  //           end: si.endDateTime,
  //           title: eventtypes.includes(si.appointmentType) ? si.appointmentType : si.description,
  //           allDay: si.allDay,
  //         }));
  //         setEvents(mapped);
  //       });
  //   };
  //   fetchSched().catch(console.error);
  // }, [user]);

  // const handleSaveResponseTime = () => {
  //   if (responseTime) {
  //     const clone: ProfessionalUser = JSON.parse(JSON.stringify(currentProfessional));
  //     if (!clone.schedulingpreferences) clone.schedulingpreferences = { responseTimeInDays: responseTime };
  //     clone.schedulingpreferences.responseTimeInDays = responseTime;
  //     updateProfessionalDetails(user as UserAuth, clone).then((res) => console.log(res.status));
  //   }
  // };

  const handleSave = () => {
    const clone: ProfessionalUser = JSON.parse(JSON.stringify(currentProfessional));
    if (!clone.schedulingpreferences) clone.schedulingpreferences = { responseTimeInDays: responseTime };
    if (genAvailability) clone.schedulingpreferences.generalAvailability = genAvailability;
    if (responseTime) clone.schedulingpreferences.responseTimeInDays = responseTime;
    clone.schedulingpreferences.acceptingNewClients = acceptingNews;
    updateProfessionalDetails(user as UserAuth, clone as ProfessionalUser).then((result) => {
      setCurrentProfessional(clone);
      if (result.ok) {
        toast({
          title: 'Profile Saved',
          description: 'Data saved successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Flex flex={1} flexDir="column" px={12} py={8}>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Text variant="header" color="charcoal" fontWeight="bold" mr={6}>
          Availability
        </Text>
      </Flex>
      <Flex mt={2}>
        <Text fontSize="sm" fontWeight="bold" mr={2}>
          Accepting New Clients?
        </Text>
        <Switch
          size="md"
          colorScheme="orange"
          isChecked={acceptingNews}
          onChange={(e) => {
            if (e.target.checked) {
              setAcceptingNews(true);
            } else {
              setAcceptingNews(false);
            }
          }}
        />
      </Flex>
      <Flex flexDirection="row">
        <Flex flexDir="column" alignItems="flex-start" justifyContent="flex-start">
          <Flex flexDir="column" mt={4}>
            <Text fontSize="sm" fontWeight="bold" mt={4} mb={2}>
              Response Time
            </Text>
            <Text fontSize="xs" maxWidth="80%">
              Average time you plan to respond to clients. Enter 0 for same day.
            </Text>
            <HStack mt={2}>
              <Input size="xs" maxWidth="40%" value={responseTime} onChange={(e) => setResponseTime(e.target.value)} />
              <Text>day(s)</Text>
            </HStack>
          </Flex>
        </Flex>
        <Flex flexDir="column" alignItems="flex-start" justifyContent="flex-start">
          <Flex flexDir="column" mt={4}>
            <Text fontSize="sm" fontWeight="bold" mt={4} mb={2}>
              Details
            </Text>
            <Text fontSize="xs" maxWidth="80%">
              Please enter your general availability (best times/days/etc.)
            </Text>
            <HStack mt={2}>
              <Input maxLength={40} size="xs" value={genAvailability} onChange={(e) => setGenAvailability(e.target.value)} />
            </HStack>
          </Flex>
        </Flex>
      </Flex>
      <Flex ml="10%" mt={12}>
        <Button variant="solid" my={12} width="20%" onClick={() => handleSave()}>
          Save
        </Button>
      </Flex>
    </Flex>
  );
}

export default AvailabilityScreen;
