export interface OutfitQuery {
  /**
   * A string that identifies the User the outfit belongs to
   */
  userid?: string;
  /**
   * sort field
   */
  sf?: string;
  /**
   * sort direction (ASC or DESC)
   */
  sd?: string;
  /**
   * page size
   */
  ps?: number;
  /**
   * page offset
   */
  po?: number;
  /**
   * userid of the user that created the outfit (only if different from the owner)
   */
  createdby?: string;
  /**
   * value to search for
   */
  searchterm?: string;
  ownership?: string;
  tags?: string[];
  isproforclient?: boolean;
}

export const defaultOutfitQuery = {
  po: 1,
  ps: 40,
  sf: 'createddate',
  sd: 'desc',
  userid: '', // this tells the service to return items for the client
  ownership: 'all',
  searchterm: undefined,
  isproforclient: true,
};
