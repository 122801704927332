// eslint-disable-next-line import/prefer-default-export
export const enum WardrobeItemCategory {
  All = 'all',
  Tops = 'tops',
  Bottoms = 'bottoms',
  Dresses = 'dress',
  Shoes = 'shoes',
  Outerwear = 'outerwear',
  Swimwear = 'swimwear',
  Accessories = 'accessories',
  Undetermined = 'undetermined',
}

export const WardrobeItemSubCategories = [
  'All',
  'Bags',
  'Belts',
  'Blazers',
  'Blouse',
  'Capris',
  'Coat',
  'Dresses',
  'Gloves',
  'Hat',
  'Heels',
  'Innerwear Vests',
  'Jackets',
  'Jeans',
  'Jeggings',
  'Jewelry',
  'Jumpsuit',
  'Leggings',
  'Pants',
  'Rompers',
  'Sandals',
  'Scarves',
  'Shirts',
  'Shoes',
  'Shorts',
  'Shrug',
  'Skirts',
  'Stockings',
  'Sunglasses',
  'Sweaters',
  'Sweatshirts',
  'Tank Tops',
  'Tops',
  'Track Pants',
  'Tracksuits',
  'Tshirts',
  'Tunics',
  'Waistcoat',
  'Other',
];

export const WardrobeItemSeasonality = ['All', 'Summer', 'Fall', 'Winter', 'Spring'];
