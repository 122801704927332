import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Text, Flex, Spacer, Slide, Image, Link, Button, HStack } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, logout } from '../config/firebase';
import { CurrentProfessionalContext, CurrentProfessionalContextType } from '../contexts/CurrentProfessionalProvider';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import useWindowDimensions from '../hooks/useWindowDimensions';
import colors from '../theme/foundations/colors';

type NavButtonProps = {
  text: string;
  iconname: string;
  route: string;
  showWarningIcon?: boolean;
};

function NavButton({ text, iconname, route, showWarningIcon = false }: NavButtonProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentlocation = location.pathname === '/' ? 'home' : location.pathname;
  const isCurrentTab = currentlocation === route;
  const iconpath = isCurrentTab ? `/icons/${iconname}_active.png` : `/icons/${iconname}.png`;
  const bordershorthand = `4px solid ${colors.orange[400]};`;
  return (
    <Flex flexDirection="row" px={6} alignItems="center" py={3} borderLeft={isCurrentTab ? bordershorthand : undefined} onClick={() => navigate(route)}>
      <Image src={iconpath} alt="menu" flex={1} ml={isCurrentTab ? 0 : '4px'} />
      <Flex flex={9} minWidth="230px" alignItems="center">
        <Text px={4} fontSize={16} color={isCurrentTab ? 'orange.400' : 'charcoal'}>
          {text}
        </Text>
        {showWarningIcon && <WarningTwoIcon w={4} h={4} color="orange.400" />}
      </Flex>
    </Flex>
  );
}

function LeftNav() {
  const { height, width } = useWindowDimensions();
  const y = height || '100%;';
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(CurrentUserContext);
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);

  return (
    <Flex flexDirection="column" flex={1} minH={y} bg="white" borderRightWidth={0.25}>
      <NavButton text="Home" iconname="home" route="/home" />
      <NavButton text="Requests" iconname="requests" route="/requests" />
      <NavButton
        text="Availability"
        iconname="calendar"
        route="/availablity"
        // showWarningIcon={currentProfessional && currentProfessional.onboardingRequirements?.availabilityRequired}
      />
      <NavButton text="Clients" iconname="clients" route="/clients" />
      <NavButton text="Insights & Earnings" iconname="dollar" route="/dashboard" />
      <NavButton text="Featured Content" iconname="featured" route="/featuredcontent" />
      <NavButton text="Resources" iconname="resources" route="/resources" />
      <Button
        color="black"
        mt={8}
        mx={6}
        justifyContent="flex-start"
        onClick={() => {
          setCurrentProfessional(undefined);
          setCurrentUser(undefined);
          logout();
          navigate('/');
        }}
      >
        Logout
      </Button>
    </Flex>
  );
}

export default LeftNav;
