/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Box, Spinner, Image, chakra } from '@chakra-ui/react';
import { getDownloadURL, getStorage, uploadBytes, ref } from 'firebase/storage';
import { app } from '../config/firebase';
import { FIREBASE_IMAGE_BASE_URL } from '../config/ServiceConstants';
import AuthorizedUserMediaAssetDisplay from './AuthorizedUserMediaAssetDisplay';
// import VideoDisplayItem from './VideoDisplayItem';

type UserMediaAssetDisplayProps = {
  uri: string;
  type: string;
  height: string | number;
  width: string | number;
  rotation?: string | number;
  //   style: StyleProp<ImageStyle>;
};

function UserMediaAssetDisplay({ uri, type, height, width, rotation, ...rest }: UserMediaAssetDisplayProps): React.ReactElement {
  const [refurl, setRefUrl] = useState('');
  // const storage = useMemo(() => getStorage(app), []);
  let storage: any; // TODO: revert to memo
  try {
    storage = getStorage(app);
  } catch (err) {
    console.log('ERROR: UserMediaAssetDisplay.getStorage()-', err);
    console.error(err);
    console.log('Firebase Storage bucket: ', process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);
  }

  useEffect(() => {
    const fetch = async () => {
      if (!storage) {
        console.log('ISSUE: storage is null.  UserMediaAssetDisplay.');
        return;
      }
      await getDownloadURL(ref(storage, uri))
        .then((url) => setRefUrl(url))
        .catch((err) => console.log(err));
    };
    if (!uri) return;
    if (uri.includes(FIREBASE_IMAGE_BASE_URL)) {
      console.log('fb uri: ', uri);
      fetch().catch((er) => console.log(er));
    } else {
      setRefUrl(uri);
    }
  }, [storage, uri]);

  // if (!refurl || refurl.length < 1) return <Spinner />;
  if (!refurl || refurl.length < 1) return <Box />;

  if (type?.startsWith('image') || type?.startsWith('photo')) {
    return <Image src={refurl} height={height} width={width} transform={rotation ? `rotate(${rotation}deg)` : undefined} {...rest} fit="contain" alt="img" />;
  }
  //   if (type?.startsWith('video')) {
  //     return (
  //       <VideoDisplayItem
  //         uri={refurl}
  //         autoplay={false}
  //         height={height as number}
  //         width={width as number}
  //         {...rest}
  //       />
  //     );
  //   }
  return <Box />;
}

// const FactoryAuthorizedMediaAssetDisplay = Factory(AuthorizedMediaAssetDisplay);
// export default UserMediaAssetDisplay;
export default chakra(UserMediaAssetDisplay);
